import * as React from "react";

export default function CancelButton({ className, color, onClick }: { className?: string, color?: string, onClick?: (event: React.MouseEvent<HTMLDivElement>) => void }) {
    return (
        <div className={`${className} hover:cursor-pointer pointer-events-auto`} onClick={onClick}>
            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.5528 11.5528H17.3291V5.77638H23.1055V0H28.8819V5.77638H23.1055V11.5528H17.3291V17.3291H23.1055V23.1055H28.8819V28.8819H23.1055V23.1055H17.3291V17.3291H11.5528V23.1055H5.77638V28.8819H0V23.1055H5.77638V17.3291H11.5528V11.5528H5.77638V5.77638H0V0H5.77638V5.77638H11.5528V11.5528Z" fill={color || "white"}/>
            </svg>
        </div>
    )
}