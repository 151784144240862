import * as React from "react";
import ContractCard from "@/components/atomics/contract-card";
import OwnerView from "@/components/atomics/owner-view";
import { useNavigate, useParams } from "react-router-dom";
import OwnerAvatar from "@/components/molecules/owner/owner-avatar";
import StatCard from "@/components/atomics/stat-card";
import TextArea from "@/components/atomics/text-area";
import Social from "@/components/molecules/owner/social";
import ImageUploadButton from "@/components/atomics/image-upload-button";
import EditView from "@/components/atomics/edit-view";
import { useState, useMemo, useEffect } from "react";
import { OwnerData } from "@/types";
import { useWallet } from "@/context/WalletContext";
import { useTelegram } from "@/hooks/useTelegram";
import EditButton from "@/components/atomics/edit-button";
import InviteFriendButton from "@/components/atomics/invite-friend-button";
import ShareUrl from "@/components/atomics/share-url";
import { backend } from "@/services/backend";
import { enqueueSnackbar } from "notistack";
import CancelButton from "@/components/atomics/cancel-button";
import CollapsibleCard from "@/components/atomics/collapsible-card";
import { useOwner } from "@/context/OwnerContext";
import Input from "@/components/atomics/Input";
import ReportButton from "@/components/atomics/report-button";
import MotionModal from "@/components/atomics/motion-modal";
import UploadAvatar from "@/components/atomics/upload-avatar";
interface OwnerProps {
  className?: string;
  ownerDatas: OwnerData | null;
}

export interface OwnerState {
  username: string;
  avatar: string;
  avatarId: number;
  background: string;
  backgroundId: number;
  info: string;
  stats: Array<{ title: string; subtitle: string; value: number; isVisible: boolean }>;
  socials: Array<{ name: string; link: string }>;
}

interface ViewState {
  isOwnerView: boolean;
  isEditView: boolean;
  showOwnerView: boolean;
  openCards: {
    info: boolean;
    social: boolean;
  };
}

export const DEFAULT_STATE: OwnerState = {
  username: '',
  avatar: '',
  avatarId: -1,
  background: '',
  backgroundId: -1,
  info: '',
  stats: [
    { title: 'Created', subtitle: 'COLLECTIONS', value: 0, isVisible: true },
    { title: 'Minted', subtitle: 'NFTs', value: 0, isVisible: true },
    { title: 'Earned', subtitle: 'TON', value: 0, isVisible: true }
  ],
  socials: [
    { name: 'telegram', link: '' },
    { name: 'rocket', link: '' },
    { name: 'twitter', link: '' },
    { name: 'getgems', link: '' }
  ]
};

const isValidSocialUrl = (url: string, name: string): boolean => {
  if (!url) return true; // Allow empty/null values
  try {
    new URL(url);
    return true;
  } catch {
    enqueueSnackbar(`${name} is invalid`, { variant: 'error', autoHideDuration: 1000 });
    return false;
  }
};

export default function OwnerEditView({ className, ownerDatas }: OwnerProps) {
  const navigate = useNavigate();
  const { address } = useParams();
  const { walletAddress } = useWallet();
  const { isAuthenticated, user } = useTelegram();
  const { isEditView, setIsEditView, isOwnerView, setIsOwnerView } = useOwner();

  const [isChange, setIsChange] = useState(false);
  const [viewState, setViewState] = useState<ViewState>({
    isOwnerView: isOwnerView,
    isEditView: isEditView,
    showOwnerView: false,
    openCards: {
      info: false,
      social: false,
    }
  });

  useEffect(() => {
    setViewState(prev => ({
      ...prev,
      isEditView: isEditView
    }));
  }, [isEditView]);

  const [ownerData, setOwnerData] = useState<OwnerState>(DEFAULT_STATE);
  const [originalOwnerData, setOriginalOwnerData] = useState<OwnerState>(DEFAULT_STATE);
  const [files, setFiles] = useState<{ avatar: File | null; background: File | null }>({
    avatar: null,
    background: null
  });

  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [showBackgroundModal, setShowBackgroundModal] = useState(false);
  const stats = useMemo(() => ({
    created_collections: ownerDatas?.collections?.length || 0,
    minted_nfts: ownerDatas?.collections?.reduce((sum, collection) => sum + collection.next_item_index, 0) || 0,
    earned: Number((ownerDatas?.collections?.reduce((sum, collection) =>
      sum + (collection.next_item_index * collection.price / 1000000000), 0) || 0).toFixed(2)),
  }), [ownerDatas]);

  useEffect(() => {
    if (!address || ownerData.username) return;
    const prefix = address.slice(0, 4);
    const suffix = address.slice(-4);
    setOwnerData(prev => ({
      ...prev,
      username: `USER#${prefix}...${suffix}`
    }));
  }, [address, ownerData.username]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOwnerData = async () => {
      try {
        if (!address) {
          setIsLoading(false);
          return;
        }

        const [photos, description, socials, username] = await Promise.all([
          backend.getOwnerPhotos(address),
          backend.getWalletDescription(address),
          backend.getUserSocials(address),
          backend.getUserDisplayName(address)
        ]);

        const avatarPhotos = photos.filter(p => p.category === 'avatar');
        const latestAvatar = avatarPhotos.length > 0
          ? avatarPhotos.reduce((prev, curr) => prev.id > curr.id ? prev : curr)
          : { id: -1, url: '' };

        const backgroundPhotos = photos.filter(p => p.category === 'cover');
        const latestBackground = backgroundPhotos.length > 0
          ? backgroundPhotos.reduce((prev, curr) => prev.id > curr.id ? prev : curr)
          : { id: -1, url: '' };

        const socialLinksMap = socials.reduce((acc, social) => {
          acc[social.name] = social.link;
          return acc;
        }, {});

        const updatedSocials = DEFAULT_STATE.socials.map(social => ({
          ...social,
          link: socialLinksMap[social.name] || ''
        }));

        const initialState = {
          username: username || '',
          avatar: latestAvatar.url || '/assets/owner-avatar.gif',
          avatarId: latestAvatar.id,
          background: latestBackground.url || '/assets/owner-bg.gif',
          backgroundId: latestBackground.id,
          info: description,
          socials: updatedSocials,
          stats: DEFAULT_STATE.stats
        };

        setOwnerData(initialState);
        setOriginalOwnerData(initialState);
        setIsLoading(false);

      } catch (error) {
        console.error('Error fetching owner data:', error);
        alert(error);
        setIsLoading(false);
      }
    };

    fetchOwnerData();
  }, [address]);


  useEffect(() => {
    if (walletAddress) {
      if (walletAddress === address) {
        setViewState(prev => ({
          ...prev,
          showOwnerView: true
        }));
      } else {
        setViewState(prev => ({
          ...prev,
          showOwnerView: false
        }));
      }
    }
  }, [walletAddress, address]);

  useEffect(() => {
    const hasChanges =
      ownerData.username !== originalOwnerData.username ||
      ownerData.info !== originalOwnerData.info ||
      ownerData.avatar !== originalOwnerData.avatar ||
      ownerData.background !== originalOwnerData.background ||
      JSON.stringify(ownerData.socials) !== JSON.stringify(originalOwnerData.socials) ||
      JSON.stringify(ownerData.stats) !== JSON.stringify(originalOwnerData.stats);

    setIsChange(hasChanges);
  }, [ownerData, originalOwnerData]);

  const handleViewToggle = () => {
    setViewState(prev => ({
      ...prev,
      isOwnerView: !prev.isOwnerView
    }));
    setIsOwnerView(!isOwnerView);
  }

  const showEdit = () => {
    setIsEditView(true);
  }

  const handleSave = async () => {
    // Update for you
    if (originalOwnerData.username !== ownerData.username) {
      const usernameResponse = await backend.setUserDisplayName(ownerData.username);
      if (usernameResponse) {
        enqueueSnackbar('Username updated', { variant: 'success', autoHideDuration: 1000 });
      } else {
        // hasError = true;
        enqueueSnackbar('Username update failed', { variant: 'error', autoHideDuration: 1000 });
      }
    }

    if (originalOwnerData.avatar !== '/assets/owner-avatar.gif' && ownerData.avatar === '/assets/owner-avatar.gif') {
      const avatarResponse = await backend.deleteOwnerPhoto([originalOwnerData.avatarId]);

      if (avatarResponse) {
        setOwnerData(prev => ({
          ...prev,
          avatar: '/assets/owner-avatar.gif',
          avatarId: -1
        }));
        enqueueSnackbar('Avatar deleted', { variant: 'success', autoHideDuration: 1000 });
      } else {
        setOwnerData(prev => ({
          ...prev,
          avatar: originalOwnerData.avatar,
          avatarId: originalOwnerData.avatarId
        }));
        enqueueSnackbar('Avatar delete failed', { variant: 'error', autoHideDuration: 1000 });
      }
    }
    /* if (!isAuthenticated) {
      setIsEditView(false);
      enqueueSnackbar('Please login to edit', { variant: 'error', autoHideDuration: 1000 });
      return;
    } */

    const invalidSocials = ownerData.socials.filter(social => !isValidSocialUrl(social.link, social.name));
    if (invalidSocials.length > 0)
      return;

    try {
      const initData = window.Telegram?.WebApp?.initData || '';
      let hasError = false;

      if (files.avatar) {
        const avatarResponse = await backend.uploadOwnerPhoto(
          files.avatar,
          address!,
          'avatar',
        );
        if (avatarResponse) {
          enqueueSnackbar('Avatar upload successful', { variant: 'success', autoHideDuration: 1000 });
        } else {
          hasError = true;
          enqueueSnackbar('Avatar upload failed', { variant: 'error', autoHideDuration: 1000 });
        }
      }

      if (files.background) {
        const backgroundResponse = await backend.uploadOwnerPhoto(
          files.background,
          address!,
          'cover'
        );
        if (backgroundResponse) {
          enqueueSnackbar('Background upload successful', { variant: 'success', autoHideDuration: 1000 });
        } else {
          hasError = true;
          enqueueSnackbar('Background upload failed', { variant: 'error', autoHideDuration: 1000 });
        }
      }

      if (originalOwnerData.info !== ownerData.info) {
        const descriptionResponse = await backend.updateWalletDescription({
          address: address!,
          text: ownerData.info
        });
        if (descriptionResponse) {
          enqueueSnackbar('Description updated', { variant: 'success', autoHideDuration: 1000 });
        } else {
          hasError = true;
          enqueueSnackbar('Description update failed', { variant: 'error', autoHideDuration: 1000 });
        }
      }

      if (originalOwnerData.socials !== ownerData.socials) {
        const socialsResponse = await backend.setUserSocials({
          address: address!,
          socials: ownerData.socials
        });
        if (socialsResponse) {
          enqueueSnackbar('Socials updated', { variant: 'success', autoHideDuration: 1000 });
        } else {
          hasError = true;
          enqueueSnackbar('Socials update failed', { variant: 'error', autoHideDuration: 1000 });
        }
      }

      if (hasError) {
        setOwnerData(originalOwnerData);
      } else {
        setOriginalOwnerData(ownerData);
      }

    } catch (error) {
      console.error('Error uploading photos:', error);
      enqueueSnackbar('Error uploading photos', { variant: 'error', autoHideDuration: 1000 });
      setOwnerData(originalOwnerData);
    }

    setIsEditView(false);
  };

  const handleCancel = () => {
    setOwnerData(originalOwnerData);
    setIsEditView(false);
    // setFiles({
    //   avatar: null,
    //   background: null
    // });
  };

  const updateBackground = (file) => {
    if (file) {
      setFiles(prev => ({
        ...prev,
        background: file
      }));
      setOwnerData(prev => ({
        ...prev,
        background: URL.createObjectURL(file)
      }));
    }
  }

  const updateAvatar = (file) => {
    if (file) {
      setFiles(prev => ({
        ...prev,
        avatar: file
      }));
      setOwnerData(prev => ({
        ...prev,
        avatar: URL.createObjectURL(file)
      }));
    }
  }

  const removeAvatar = async () => {
    try {
      setOwnerData(prev => ({
        ...prev,
        avatar: '/assets/owner-avatar.gif',
        avatarId: -1
      }));
    } catch (error) {
      console.error('Error setting default avatar:', error);
      // setOwnerData(prev => ({
      //   ...prev,
      //   avatar: ''
      // }));
      // setFiles(prev => ({
      //   ...prev,
      //   avatar: null
      // }));
    }
  };

  const updateInfo = (e) => {
    setOwnerData(prev => ({
      ...prev,
      info: e.target.value
    }));
  }
  const updateState = (index) => {
    console.log(index, "index")
    setOwnerData(prev => ({
      ...prev,
      stats: prev.stats.map((s, i) =>
        i === index ? { ...s, isVisible: !s.isVisible } : s
      )
    }));
  }

  const handleSocialChange = (index: number, value: string) => {
    setOwnerData(prev => ({
      ...prev,
      socials: prev.socials.map((social, i) =>
        i === index ? { ...social, link: value } : social
      )
    }));
  };

  return (
    <>
      <MotionModal
        isOpen={showAvatarModal}
        className="fixed left-0 right-0 mx-auto top-0 max-w-[430px] w-[100vw] h-[100vh] bg-black flex justify-center z-[999] overflow-y-auto scrollbar-hide"
      >
        <UploadAvatar
          title="AVATAR"
          myNfts={ownerDatas?.items || []}
          myCollections={ownerDatas?.collections || []}
          onAvatarChange={(file) => {
            updateAvatar(file);
            setShowAvatarModal(false);
          }}
          onClose={() => setShowAvatarModal(false)}
        />
      </MotionModal>

      <MotionModal
        isOpen={showBackgroundModal}
        className="fixed left-0 right-0 mx-auto top-0 max-w-[430px] w-[100vw] h-[100vh] bg-black flex justify-center z-[999] overflow-y-auto scrollbar-hide"
      >
        <UploadAvatar
          title="BACKGROUND"
          myNfts={ownerDatas?.items || []}
          myCollections={ownerDatas?.collections || []}
          onAvatarChange={(file) => {
            updateBackground(file);
            setShowBackgroundModal(false);
          }}
          onClose={() => setShowBackgroundModal(false)}
        />
      </MotionModal>
      <div className={`w-full relative`}>
        {!isLoading && (
          <>
            {!viewState.isEditView && viewState.showOwnerView && (
              <OwnerView
                isOwnerView={viewState.isOwnerView}
                handleViewToggle={handleViewToggle}
              />
            )}
            {viewState.isEditView && viewState.showOwnerView && (
              <EditView
                className="fixed top-[90px] left-[50%] translate-x-[-50%] z-[1] bg-black"
                handleSave={handleSave}
                handleCancel={handleCancel}
                isChange={isChange}
              />
            )}
            <div className="relative w-full h-[180px]">
              <img src={ownerData.background} className={`${viewState.isEditView ? 'opacity-40' : ''}`} alt="background" />
              {viewState.isEditView && (
                <ImageUploadButton
                  className="absolute top-[24px] right-[24px]"
                  maxResolution={{ width: 2880, height: 640 }}
                  maxFileSize={5}
                  onFileSelect={(file) => {
                    updateBackground(file);
                  }}
                  onClick={() => setShowBackgroundModal(true)}
                />
              )}
              {viewState.isOwnerView && viewState.showOwnerView && (
                <>
                  <EditButton
                    className={`${viewState.isEditView ? 'opacity-40' : ''} absolute bottom-[-52px] left-[40px] z-[1] hover:cursor-pointer`}
                    color={`${viewState.isEditView ? 'white' : '#0099FE'}`}
                    onClick={() => showEdit()}
                  />
                </>
              )}

              <ReportButton
                className={`${viewState.isEditView || viewState.showOwnerView ? 'hidden' : ''} absolute bottom-[-49px] left-[28px] z-[1] hover:cursor-pointer`}
                walletAddress={walletAddress}
              />

              <ShareUrl
                className={`${viewState.isEditView ? 'opacity-40 pointer-events-none' : ''} absolute bottom-[-49px] right-[28px] z-[1] hover:cursor-pointer`}
                buttonColor={`${viewState.isEditView ? 'white' : '#0099FE'}`}
                path={`owner/${address}`}
                walletAddress={walletAddress}
              />

              <div className="w-full absolute left-[50%] translate-x-[-50%] translate-y-[-80px] flex flex-col items-center justify-center">
                <div className="relative">
                  <OwnerAvatar
                    className={`${viewState.isEditView ? 'opacity-40' : ''} w-[143px] `}
                    image={ownerData.avatar}
                  />
                  {viewState.isEditView && (
                    <>
                      <ImageUploadButton
                        className="absolute z-[10] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                        maxResolution={{ width: 1024, height: 1024 }}
                        maxFileSize={2}
                        onFileSelect={(file) => {
                          updateAvatar(file);
                        }}
                        onClick={() => setShowAvatarModal(true)}
                      />
                      {ownerData.avatar !== '/assets/owner-avatar.gif' && (
                        <CancelButton
                          className="absolute z-[11] top-[0%] right-[0%] w-[31px] h-[31px]"
                          color="red"
                          onClick={() => { removeAvatar() }} />
                      )}
                    </>

                  )}
                </div>

                <div className={`${viewState.isEditView ? 'opacity-100' : ''} text-[32px]`}>
                  {!viewState.isEditView ? (
                    <span>{ownerData.username}</span>
                  ) : (
                    <Input
                      className="w-[calc(100vw-60px)] h-[32px] text-[32px] -mt-[15px] mb-[20px]"
                      value={ownerData.username}
                      onChange={(e) => setOwnerData(prev => ({ ...prev, username: e.target.value }))}
                    />
                  )}
                </div>
                <ContractCard
                  className={`${viewState.isEditView ? 'opacity-40 pointer-events-none' : ''} pt-[10px] pb-[10px] w-[calc(100vw-61px)] max-w-[374px]`}
                  length={12}
                  text="WALLET"
                  address={address || ''} />
              </div>
            </div>

            <div className="px-[30px] pt-[193px]">
              <div className="flex justify-center gap-[10px] pb-[50px]">
                <StatCard
                  key="collections"
                  title="Created"
                  subtitle="COLLECTIONS"
                  value={stats.created_collections}
                  isVisible={ownerData.stats[0].isVisible}
                  isOwnerView={viewState.isOwnerView}
                  isEditView={viewState.isEditView}
                  onChange={() => updateState(0)}
                />
                <StatCard
                  key="nfts"
                  title="Minted"
                  subtitle="NFTs"
                  value={stats.minted_nfts}
                  isVisible={ownerData.stats[1].isVisible}
                  isOwnerView={viewState.isOwnerView}
                  isEditView={viewState.isEditView}
                  onChange={() => updateState(1)}
                />
                <StatCard
                  key="earned"
                  title="Earned"
                  subtitle="TON"
                  value={stats.earned}
                  isVisible={ownerData.stats[2].isVisible}
                  isOwnerView={viewState.isOwnerView}
                  isEditView={viewState.isEditView}
                  onChange={() => updateState(2)}
                />
              </div>

              <CollapsibleCard
                editTitle="MY INFO"
                viewTitle="info:"
                className="text-[30px]"
                viewClassName="text-[23px]"
                isEditView={viewState.isEditView}
              >
                <TextArea
                  className=""
                  value={ownerData.info}
                  totalTextCount={700}
                  notEditable={!viewState.isEditView}
                  onChange={(e) => {
                    updateInfo(e)
                  }}
                />
              </CollapsibleCard>

              {(ownerData.socials.some(social => social.link) || viewState.isEditView) && (
                <CollapsibleCard
                  editTitle="My LINKS"
                  viewTitle=""
                  className="text-[30px]"
                  viewClassName="text-[23px]"
                  isEditView={viewState.isEditView}
                >
                  <div className={`flex ${viewState.isEditView ? 'flex-col gap-[10px]' : 'flex-row gap-[20px]'} py-[30px]`}>
                    {ownerData.socials.map((social, index) => (
                      <Social
                        key={index}
                        {...{ [social.name]: social.link }}
                        name={social.name}
                        value={social.link}
                        onChange={(value) => handleSocialChange(index, value)}
                        isEditView={viewState.isEditView}
                      />
                    ))}
                  </div>
                </CollapsibleCard>
              )}
            </div>
          </>
        )}
      </div>
    </>

  );
}