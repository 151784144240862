import * as React from "react";
import Mint from "@/components/organisms/mint";
import Header from "@/components/template/dashboard-layout/header";
import Footer from "@/components/template/dashboard-layout/footer";
import { useLocation, useParams } from "react-router-dom";
import { getCollectionDataFromBackend, getCollectionMasterData } from "@/functions/mintCollection";
import { useState, useEffect } from "react";
import { CollectionTotalData, CollectionMasterStandardData } from "@/types";
import { CollectionDataCache } from "@/types/collection";
import { backend } from "@/services/backend";

export default function NFTMintPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  
  const [collectionInfo, setCollectionInfo] = useState<CollectionDataCache | null>(null);
  const [collectionMasterInfo, setCollectionMasterInfo] = useState<CollectionMasterStandardData | null>(null);
  const { collection } = useParams();

  // Убираем query-параметры из collection
  const cleanCollection = collection?.split("?")[0];

  useEffect(() => {
    const fetchCollectionData = async () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (!cleanCollection) {
        setError(new Error("Collection ID is required"));
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        const collectionData = await backend.getCollectionData(cleanCollection);
        setCollectionInfo(collectionData);
        // setCollectionMasterInfo(masterData);
      } catch (err) {
        setError(err instanceof Error ? err : new Error("Failed to fetch collection data"));
      } finally {
        setIsLoading(false);
      }
    };

    fetchCollectionData();
  }, [collection]);

  return (
    <>
      <Header />
      {isLoading ? (
        <div className="min-h-[100vh] flex justify-center items-center text-[30px]">Loading...</div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : collectionInfo  ? (
        <Mint 
          collection={collection}
          collectionInfo={collectionInfo}
        />
      ) : (
        <div>No collection data available</div>
      )}
      <Footer />
    </>
  );
}