import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CollectionCard from "@/components/molecules/create-collection/collection-card";
import { useOwner } from "@/context/OwnerContext";
import LoadMoreButton from "@/components/atomics/load-more-button";
import { useWallet } from "@/context/WalletContext";
import CollapsibleGroup from "@/components/atomics/collapsible-group";
import { OwnerCollectionData } from "@/types/collection";
import { ownerdata } from "@/data/owner-data";
interface OwnerCollectionProps {
    collectionData: OwnerCollectionData[];
}

const limit_count = 4;

export default function OwnerCollection({ collectionData }: OwnerCollectionProps) {
    const navigate = useNavigate();
    const [displayCount, setDisplayCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const [showOwnerView, setShowOwnerView] = useState(false);
    const { isEditView, isOwnerView } = useOwner();
    const { walletAddress } = useWallet();
    const { address } = useParams();

    console.log(displayCount)

    useEffect(() => {
        setDisplayCount(Math.min(limit_count, collectionData.length))
    }, [collectionData])

    useEffect(() => {
        if (walletAddress) {
            if (walletAddress === address) {
                setShowOwnerView(true);
            } else {
                setShowOwnerView(false);
            }
        }
    }, [walletAddress, address]);

    const handleLoadMore = () => {
        setIsLoading(true);
        setTimeout(() => {
            if (displayCount >= collectionData.length) {
                setDisplayCount(limit_count)
            } else {
                setDisplayCount(prev => prev + limit_count);
            }
            setIsLoading(false);
        }, 300);
    };


    return (
        <CollapsibleGroup
            isEditView={isEditView}
            editTitle={isOwnerView && showOwnerView ? "MY COLLECTION" : "WALLET COLLECT."}
            viewTitle={isOwnerView && showOwnerView ? "MY COLLECTION" : "WALLET COLLECT."}
            className="w-full px-[30px] text-[30px]"
            extendedViewClassName={`w-full px-[30px] py-[15px] text-[30px] ${collectionData.length > 0 ? 'bg-white text-black' : 'bg-black text-white'}`}
            collapsedViewClassName="w-full px-[30px] py-[15px] text-[30px] bg-black text-white"
        >
            <div className={`w-full bg-white px-[30px] ${collectionData.length > 0 ? 'pt-[20px] pb-[20px]' : ''}`}>
                <div className="grid grid-cols-2 gap-[10px] transition-all duration-500 ease-in-out">
                    {collectionData?.slice(0, displayCount).map((item, index) => (
                        <CollectionCard
                            key={index}
                            className={`w-full flex-shrink-0 transition-all duration-300 ease-in-out ${isLoading ? 'animate-pulse opacity-60' : 'opacity-100'
                                }`}
                            onClick={() => navigate(`/collection/${item.address}`)}
                            data_url={item.data_url}
                            value={item.price * item.next_item_index / 1000000000}
                            totalMintCount={item.mint_limit}
                            currentMintedCount={item.next_item_index}
                        />
                    ))}
                </div>
                {collectionData.length > limit_count && (
                    <LoadMoreButton
                        isLoading={isLoading}
                        hasMore={displayCount < collectionData.length}
                        onLoadMore={handleLoadMore}
                    />
                )}
            </div>
        </CollapsibleGroup>
    )
}
