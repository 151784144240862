export const helpDescription = {
    'Step2': 'This is the second step where you set up your collection details.',
    'MINT LIMIT': 'This parameter is responsible for the limit, greater than which it is impossible to create nft from this collection. 0 by default means infinite creation.',
    'TIME LIMIT': 'Date and time when nft minting became unavailable even mint limit not reached',
    'ROYALTY_%': 'This parameter is responsible for the commission from the resale of NFTs from this collection and can be from 0 to 100 percent.',
    'PRICE IN TON': 'Enter the price in TON for each NFT in your collection.',
    'SBT': 'Soul Bound Token (SBT) is a non-transferable NFT that represents achievements or identity.',
    'COLLECTION NAME': 'The collection name must not exceed 100 characters.',
    'DESCRIPTION': 'The collection description must not exceed 700 characters and may also include links.',
    'NFT NAME': 'The default NFT name is the same as the collection. You can specify a custom name if you wish. The limit is the same: no more than 100 characters.',
    'NFT DESCRIPTION': 'The default description of the NFT is the same as the collection. If desired, you can specify a custom one. The limit is the same: no more than 700 characters.',
    'METADATA': 'Meta data allows storing custom parameters and their values ​​in NFT. The number of parameters is no more than 10. On the left is the parameter name, on the right is the parameter value. Plus - adds a parameter, minus - deletes a parameter.',
}
