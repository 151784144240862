import * as React from "react";
import ReactCrop, { type Crop } from 'react-image-crop';
import { useNavigate } from "react-router-dom";
import 'react-image-crop/dist/ReactCrop.css';
import Button from "./button";
import CancelButton from "./cancel-button";
import { CollectionDataCache, OwnerCollectionData, OwnerNftData } from "@/types";
import { backend } from "@/services/backend";
import NFTSkinCard from "./nft-skin-card";
import CollectionSkinCard from "./collection-skin-card";
import LoadMoreButton from "./load-more-button";
import { ConfirmModal } from "./confirm-modal";
import MotionModal from "./motion-modal";

interface UploadAvatarProps {
    title: string,
    myNfts: OwnerNftData[],
    myCollections: OwnerCollectionData[],
    onAvatarChange: (file: File) => void;
    onClose?: () => void;
}

export default function UploadAvatar({ title, myNfts, myCollections, onAvatarChange, onClose }: UploadAvatarProps) {
    const navigate = useNavigate();
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [selectedImage, setSelectedImage] = React.useState<string | null>(null);
    const [selectedSkin, setSelectedSkin] = React.useState<any>(null);
    const [showCropModal, setShowCropModal] = React.useState(false);
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [displayCount, setDisplayCount] = React.useState(6);
    const [lastCollections, setLastCollections] = React.useState<CollectionDataCache[]>([]);

    React.useEffect(() => {
        backend.getAllLastCollections().then((collections) => {
            setLastCollections(collections);
        });
    }, []);
    console.log(lastCollections, "________________________lastCollections____________________");


    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result as string);
                setShowCropModal(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCropComplete = (croppedFile: File) => {
        onAvatarChange(croppedFile);
        setShowCropModal(false);
        setSelectedImage(null);
    };

    const handleLoadMore = () => {
        setIsLoading(true);
        setTimeout(() => {
            if (displayCount >= lastCollections.length) {
                setDisplayCount(4);
            } else {
                setDisplayCount(prev => Math.min(prev + 4, lastCollections.length));
            }
            setIsLoading(false);
        }, 300);
    };

    return (
        <div className="flex flex-col w-full items-center text-[28px] text-white">
            <div className="fixed top-0 left-0 right-0 z-30 flex justify-between items-center p-[20px] pointer-events-none bg-[#0099FE] max-w-[430px] mx-auto">
                <span>{`EDIT ${title}`}</span>
                <CancelButton
                    onClick={onClose}
                />
            </div>
            <div className={`relative pt-[83px] w-full ${showCropModal || showConfirmModal ? 'hidden pointer-events-none' : ''}`}>
                <div className="flex flex-col w-full items-center px-[54px]">
                    <div
                        className="flex flex-col w-full text-center items-center justify-center relative cursor-pointer"
                        onClick={() => {
                            fileInputRef.current?.click();
                        }}>
                        <span className="py-4">UPLOAD PICTURE</span>
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            className="hidden"
                            onChange={handleFileSelect}
                        />
                        <div className="relative">
                            <img
                                src="/assets/upload_picture.svg"
                                alt="upload-picture"
                                className={`${title === 'BACKGROUND' ? 'aspect-[430/180] object-cover' : ''}`}
                            />
                            <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] justify-center items-center">
                                <div className="w-[46px] h-[41px] mx-auto">
                                    <img src="/assets/upload-icon.svg" alt="Upload Icon" />
                                </div>
                                <div className="flex flex-col items-center text-[13px] font-spline-sans-mono pt-[5px]">
                                    <span className="line-height-1">2000x2000</span>
                                    <span className="line-height-1">&lt;5MB</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {myNfts.length > 0 && title === 'AVATAR' && (
                        <div className="flex flex-col items-center justify-center">
                            <span className="py-4">MY SKINS</span>
                            <div className="grid gap-4 w-full grid-cols-2">
                                {myNfts.map((item) => (
                                    <NFTSkinCard
                                        className="text-[16px] text-[#747474]"
                                        key={item.address}
                                        item={item}
                                        onClick={() => {
                                            setSelectedSkin(item);
                                            setShowConfirmModal(true);
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    )}

                    {myCollections.length > 0 && title === 'BACKGROUND' && (
                        <div className="flex flex-col items-center justify-center">
                            <span className="py-4">MY SKINS</span>
                            <div className="grid gap-4 w-full grid-cols-1">
                                {myCollections.map((item) => (
                                    <CollectionSkinCard
                                        className="text-[16px] text-[#747474]"
                                        key={item.address}
                                        title={title}
                                        item={item}
                                        onClick={() => {
                                            setSelectedSkin(item);
                                            setShowConfirmModal(true);
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    )}

                    {lastCollections.length > 0 && title === 'AVATAR' && (
                        <div className="flex flex-col items-center justify-center">
                            <span className="py-4">ALL SKINS</span>
                            <div className="grid gap-4 w-full grid-cols-2">
                                {lastCollections.slice(0, displayCount).map((item) => (
                                    <CollectionSkinCard
                                        className="text-[16px] text-[#747474]"
                                        key={item.address}
                                        title={title}
                                        item={item}
                                        onClick={() => navigate(`/collection/${item.address}`)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}

                    {lastCollections.length > 0 && title === 'BACKGROUND' && (
                        <div className="flex flex-col items-center justify-center">
                            <span className="py-4">ALL SKINS</span>
                            <div className="grid gap-4 w-full grid-cols-1">
                                {lastCollections.slice(0, displayCount).map((item) => (
                                    <CollectionSkinCard
                                        className="text-[16px] text-[#747474]"
                                        key={item.address}
                                        title={title}
                                        item={item}
                                        onClick={() => navigate(`/collection/${item.address}`)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}

                    {lastCollections.length > 6 && (
                        <LoadMoreButton
                            isLoading={isLoading}
                            hasMore={displayCount < lastCollections.length}
                            onLoadMore={handleLoadMore}
                        />
                    )}
                </div>
            </div>
            {showCropModal && selectedImage && (
                <CropImageModal
                    title={title}
                    image={selectedImage}
                    onClose={() => setShowCropModal(false)}
                    onApply={handleCropComplete}
                />
            )}
            <MotionModal
                isOpen={showConfirmModal}
                className="fixed left-0 right-0 mx-auto bottom-0 max-w-[430px] w-[100vw] h-[80vh] bg-black flex justify-center z-[999] overflow-y-auto scrollbar-hide"
            >
                <ConfirmModal
                    onClose={() => setShowConfirmModal(false)}
                    onApply={() => {
                        setShowConfirmModal(false);
                    }}
                    item={selectedSkin!}
                    title={title}
                />
            </MotionModal>
        </div>
    );
}

interface CropImageModalProps {
    image: string;
    title: string;
    onClose: () => void;
    onApply: (file: File) => void;
}

function CropImageModal({ image, title, onClose, onApply }: CropImageModalProps) {
    const [crop, setCrop] = React.useState<Crop>({
        unit: 'px',
        width: title === 'BACKGROUND' ? 430 : 150,
        height: title === 'BACKGROUND' ? 180 : 150,
        x: 0,
        y: 0
    });

    React.useEffect(() => {
        const img = new Image();
        img.onload = () => {
            const cropWidth = title === 'BACKGROUND' ? 430 : 150;
            const cropHeight = title === 'BACKGROUND' ? 180 : 150;

            // Ensure crop dimensions don't exceed image dimensions
            const finalWidth = Math.min(cropWidth, img.width);
            const finalHeight = Math.min(cropHeight, img.height);

            // Calculate center position
            const centerX = Math.max(0, (img.width - finalWidth) / 2);
            const centerY = Math.max(0, (img.height - finalHeight) / 2);

            setCrop({
                unit: 'px',
                width: finalWidth,
                height: finalHeight,
                x: centerX,
                y: centerY
            });
        };
        img.src = image;
    }, [image, title]);

    const [completedCrop, setCompletedCrop] = React.useState<Crop | null>(null);
    const [scale, setScale] = React.useState(1);
    const imgRef = React.useRef<HTMLImageElement>(null);

    const getCroppedImg = (image: HTMLImageElement, crop: Crop): Promise<File> => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width ?? 0;
        canvas.height = crop.height ?? 0;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            throw new Error('No 2d context');
        }

        ctx.drawImage(
            image,
            (crop.x ?? 0) * scaleX,
            (crop.y ?? 0) * scaleY,
            (crop.width ?? 0) * scaleX,
            (crop.height ?? 0) * scaleY,
            0,
            0,
            crop.width ?? 0,
            crop.height ?? 0
        );

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                if (blob) {
                    resolve(new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' }));
                }
            }, 'image/jpeg');
        });
    };

    const handleApply = async () => {
        if (completedCrop?.width && completedCrop?.height && imgRef.current) {
            try {
                const croppedImage = await getCroppedImg(imgRef.current, completedCrop);
                onApply(croppedImage);
            } catch (e) {
                console.error(e);
            }
        }
    };

    return (
        <div className="fixed bg-black bg-opacity-50 flex items-center justify-center z-50 w-full max-w-[430px] mx-auto">
            <div className="bg-black w-full">
                <div className="flex justify-between items-center px-[20px] py-[10px] bg-[#0099FE] w-full relative">
                    <span>CROP IMAGE</span>
                    <CancelButton onClick={onClose} />
                </div>
                <div className="overflow-hidden">
                    <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={title === 'BACKGROUND' ? 16/7 : 1}
                        locked={false}
                        className="w-full h-full"
                    >
                        <img
                            ref={imgRef}
                            src={image}
                            alt="Crop me"
                            className="w-full h-full object-cover"
                            style={{ transform: `scale(${scale})` }}
                            onWheel={(e) => {
                                e.preventDefault();
                                const delta = e.deltaY;
                                const newScale = scale + (delta > 0 ? -0.1 : 0.1);
                                setScale(Math.max(0.5, Math.min(3, newScale)));
                            }}
                        />
                    </ReactCrop>
                </div>
                <div className="flex justify-center items-center px-[50px]">
                    <Button
                        className="w-full custom-aspect-320-63 text-[24px] hover:cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out"
                        image="/assets/button.svg"
                        text="APPLY"
                        onClick={handleApply}
                    />
                </div>
            </div>
        </div>
    );
}