import { telegramBotName, telegramWebAppName } from "@/config";
import { Address } from "@ton/core";

export const pathSeparator = "xXXx"; 
export const refPrefix = "zZZz";  
export const miniappUrlParamSeparator = "wWWwW"
export const root_url = `https://t.me/${telegramBotName}/${telegramWebAppName}?startapp=`

// Call to generate link
export function generateMiniAppParam (path: string, ref?: string) {
    
    let url_param = path.replace("/", pathSeparator);  // Replace '/' with '_'

    if (ref) {
        url_param = url_param + `${miniappUrlParamSeparator}ref${refPrefix}${ref}`;  // Append ref if present
    }

    return url_param;
}

export function generateMiniAppCustomUrl (path: string, ref?: string) {
    return `${root_url}${generateMiniAppParam(path, ref)}`;
}

// Call to use link

// https://t.me/Tgstarmaptester_bot/TestStarGame?startapp=hello

export function getUrlFromMiniAppParam(param: string): string {
    if (!param) return "";

    const paramStr = param.split(miniappUrlParamSeparator); // Разделяем по "_____"

    // Заменяем все "__" на "/"
    let url = paramStr[0].replace(new RegExp(pathSeparator, "g"), "/");

    // Добавляем параметр ?ref=, если он есть
    if (paramStr.length > 1) {
        url += paramStr[1].replace(`ref${refPrefix}`, "?ref=");
    }

    return url;
}