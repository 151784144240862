import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./scss/app.scss";
import "./scss/app.scss";
import { CollectionProvider } from "@/context/CollectionContext";
import CollectionCreatePage from "./pages/createCollection";
import SingleCollectionPage from "./pages/collection";
import MainPage from "./pages/mainPage";
import AccountPage from "./pages/account";
import SingleItemPage from "./pages/item";
import ConfirmWalletPage from "./pages/confirmWallet";
import { app_version, masterTonContract } from "./config";
import CreateCollectionPage from "./pages/create-collection";
import CreateCollectionStepPage from "./pages/create-collection-step";
import NFTSMintPage from "./pages/mint";
import OwnerPage from "./pages/owner";
import NftPage from "./pages/nft";
import ReferalPage from "./pages/referal";
import LeaderBoardPage from "./pages/leaderBoard";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useWallet, WalletProvider } from "@/context/WalletContext";
import WebApp from "@twa-dev/sdk";
import Version from "./components/atomics/version";
import { getUrlFromMiniAppParam } from "./utils/telegram";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { backend } from "./services/backend";
import { connector } from "./functions";

const sesionStorageVisitKey = "visited_first";

const App = () => {

  const [tonConnectUI] = useTonConnectUI();

  useEffect(() => {
    const startParam = WebApp.initDataUnsafe?.start_param;
    WebApp.expand();
    WebApp.disableVerticalSwipes();
    console.log("Platform:", WebApp.platform);

    const path = document.location.pathname;

    if (WebApp.platform === "ios" || WebApp.platform === "macos" || WebApp.platform === "weba" ||  WebApp.platform === "webk") {
      window.history.pushState(null, "", path);
    }

    /* const clearSessionStorage = () => {
      console.log("Visibility cleared")
      sessionStorage.removeItem(sesionStorageVisitKey);
      sessionStorage.removeItem("historyStack");
    };
  
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        clearSessionStorage();
      }
    }); */

    console.log("History:", window.history, "Platform:", WebApp.platform );

    connector.onStatusChange((wallet) => {
      console.log("Connector status changed", wallet?.account, wallet?.connectItems?.tonProof)
    })

    const isVisited = sessionStorage.getItem(sesionStorageVisitKey);

    console.log("Start param:", startParam, isVisited);
    const urlPath = getUrlFromMiniAppParam(startParam || "");
    console.log("Path:",urlPath, "Visited:", isVisited);
    if (startParam && !isVisited) {
      const urlPath = getUrlFromMiniAppParam(startParam);
      console.log("Path:", urlPath);
      const path = `/${getUrlFromMiniAppParam(startParam)}`;
      const currentPath = `${document.location.pathname}${document.location.search}`;
      if (path !== currentPath) {
        document.location.pathname = `${path}`;
        // window.history.back();
        // window.history.replaceState(null, "", path);
        // document.location.pathname = `${path}`;
      }
      sessionStorage.setItem(sesionStorageVisitKey, "1");
      console.log("Visited is set up")
    }
  }, [document.location.pathname]);

  return (
    <BrowserRouter>
      <SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <WalletProvider>
          <CollectionProvider>
            <Routes>
              <Route path="/collection/create" element={<CreateCollectionPage />} />
              <Route path="/owner/:address" element={<OwnerPage />} />
              <Route path="/collection/create/:step" element={<CreateCollectionStepPage />} />
              <Route path="/collection/:collection" element={<NFTSMintPage />} />
              <Route path="/nft/:id" element={<NftPage />} />
              <Route path="/walletconfirm" element={<ConfirmWalletPage />} />
              <Route path="/referal/:address" element={<ReferalPage />} />
              <Route path="/leaderboard" element={<LeaderBoardPage />} />
              {/* <Route path="/owner/:address" element={<AccountPage />}/> */}
              {/* <Route path="/collection/create" element={<CollectionCreatePage />}/> */}
              {/* <Route path="/collection/:collection" element={<SingleCollectionPage />} /> */}
              <Route path="/item/:item" element={<SingleItemPage />} />
              <Route path="/" element={<CreateCollectionPage />} />
            </Routes>
            <Version version={app_version} />
          </CollectionProvider>
        </WalletProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
};

export default App;
