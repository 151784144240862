import * as React from "react";
import SearchInput from "../../atomics/search-input";
import { HiOutlineBars2 } from "react-icons/hi2";
import { HiXMark } from "react-icons/hi2";
import { useState } from 'react';
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import { TonConnectButton, useTonConnectUI } from "@tonconnect/ui-react";
import TonWeb from 'tonweb';
import { useTelegram } from "@/hooks/useTelegram";
import { useSnackbar } from "notistack";
import { useWallet } from '@/context/WalletContext';
import { useTonConnect } from "@/hooks/useTonConnect";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from 'notistack';
import WebApp from '@twa-dev/sdk';

const stackKey = "historyStack";
let historyStack = JSON.parse(sessionStorage.getItem(stackKey) || "[]");

export default function Header() {

    const path = document.location.pathname;
    const { walletAddress, disconnect } = useWallet();
    const { isMenuOpen, toggleMenu } = useWallet();

        if (!historyStack.length || historyStack[historyStack.length - 1] !== path) {
          historyStack.push(path);
        }
    
        const handleBack = () => {
          document.location.pathname = "/"  
          /* historyStack = JSON.parse(sessionStorage.getItem(stackKey) || "[]");
          console.log("Stack:", historyStack);
          if (historyStack.length > 1) {
            historyStack.pop(); // Удаляем текущую страницу
            const prevPath = historyStack[historyStack.length - 1];
            console.log("Stack len:", historyStack);
            sessionStorage.setItem(stackKey, JSON.stringify(historyStack));
            document.location.pathname = prevPath;
          } else {
            document.location.pathname = "/"
           // WebApp.close();
          } */
        }; 

    React.useEffect(() => {
        historyStack = JSON.parse(sessionStorage.getItem(stackKey) || "[]");
        console.log("Stack from use effect:", historyStack);
        const updatedStack = [...historyStack, path];
        sessionStorage.setItem(stackKey, JSON.stringify(updatedStack));
        if (path !== "/") {
            // WebApp.MainButton.hide();
            WebApp.BackButton.show();
            WebApp.BackButton.onClick(handleBack);
        } else {
            WebApp.BackButton.hide();
            // WebApp.MainButton.show();
        }
    }, [path, walletAddress, isMenuOpen])

    const tonButtonRef = React.useRef<HTMLDivElement>(null);

    const { isAuthenticated, login: telegramLogin, logout: telegramLogout, user } = useTelegram();

    const truncateAddress = (address: string): string => {
        if (!address) return '';
        try {
            return `${address.slice(0, 8)}...${address.slice(-4)}`;
        } catch (e) {
            return `${address.slice(0, 6)}...${address.slice(-4)}`;
        }
    };

    const handleConnectWallet = () => {
        tonButtonRef.current?.querySelector('button')?.click();
    };

    const handleLogoClick = () => {
        navigate('/');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleDisconnect = () => {
        disconnect();
    };

    const navigate = useNavigate();

    const handleMyCollections = () => {
        if (!walletAddress) {
            enqueueSnackbar('Please connect your wallet first', { variant: 'warning', autoHideDuration: 1000 });
            return;
        }
        toggleMenu();
        navigate(`/owner/${walletAddress}`);
    };

    return (
        <>
            <header className='fixed z-[20] top-0 left-[50%] translate-x-[-50%] max-w-[430px] w-full flex items-center justify-between pt-[20px] pl-[10px] pr-[10px] pb-[10px] sm:p-[20px] gap-[7px] sm:gap-[10px] sl:gap-[20px] bg-black'>
                <img
                    src="/logo.png"
                    alt="logo"
                    className='hover:cursor-pointer w-[51px] h-[51px]'
                    onClick={handleLogoClick}
                />
                <SearchInput
                    className='w-full'
                    placeholder='Search'
                />
                <button
                    onClick={toggleMenu}
                    className=""
                >
                    {isMenuOpen ? (
                        <HiXMark className='text-[#4E4E4E] w-[51px] h-[31px] hover:cursor-pointer transition-all duration-300' />
                    ) : (
                        <HiOutlineBars2 className='text-[#4E4E4E] w-[51px] h-[31px] hover:cursor-pointer transition-all duration-300' />
                    )}
                </button>

                {isMenuOpen && (
                    <div
                        className="fixed top-0 right-[50%] max-w-[430px] h-screen w-full bg-black shadow-lg z-10 translate-x-[50%] overflow-y-auto overflow-x-hidden"
                    >
                        <div className="absolute top-[20px] left-[10px] sm:left-[20px]">
                            <img
                                src="/logo.png"
                                alt="logo"
                                className='w-[51px] h-[51px]'
                            />
                        </div>
                        <div className="absolute top-[28px] right-[10px] sm:right-[20px]" onClick={toggleMenu}>
                            <HiXMark className='text-[#4E4E4E] w-[51px] h-[31px] hover:cursor-pointer transition-all duration-300' />
                        </div>
                        <div className='relative min-h-full'>
                            <div className="mt-[76px] border-[#1E1E1E] border-t-[1px] pt-[16px] pl-[20px] pr-[30px] sl:pl-[20px] flex flex-col gap-[20px] pb-[20px]">
                                <div className='hover:cursor-pointer font-silkscreen text-[25px] sl:text-[30px]     '
                                    onClick={() => {
                                        toggleMenu();
                                        navigate('/')
                                    }}
                                >
                                    <span>HOME</span>
                                </div>
                                <div >
                                    {walletAddress ? (
                                        <div className='font-silkscreen text-[25px] sl:text-[30px] flex justify-between items-center gap-[15px]'
                                            onClick={handleDisconnect}>
                                            <div className="flex items-center gap-[15px]">
                                                <img
                                                    src="/assets/connected-wallet.svg"
                                                    className="w-[33px] h-[30px]"
                                                />
                                                <span className="text-[#0099FE] text-[20px] font-spline-sans-mono">{truncateAddress(walletAddress)}</span>
                                            </div>
                                            <div

                                                className="hover:cursor-pointer"
                                            >
                                                <img
                                                    src="/assets/red-cancel.svg"
                                                    className="w-[25px] h-[25px]"
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='font-silkscreen text-[25px] sl:text-[30px] flex justify-between items-center gap-[15px]'
                                            onClick={handleConnectWallet}
                                        >
                                            <p>CONNECT WALLET</p>
                                            <div>
                                                <img
                                                    src="/assets/connect-wallet.svg"
                                                    className="w-[33px] h-[30px]"

                                                />
                                                <div ref={tonButtonRef} className="absolute opacity-0 pointer-events-none">
                                                    <TonConnectButton />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className='hover:cursor-pointer font-silkscreen text-[25px] sl:text-[30px]'
                                    onClick={handleMyCollections}
                                >
                                    <span>MY PROFILE</span>
                                </div>
                                <div
                                    onClick={() => {
                                        toggleMenu();
                                        navigate('/collection/create')
                                    }}
                                    className='hover:cursor-pointer font-silkscreen text-[25px] sl:text-[30px]'
                                >
                                    <span>CREATE COLLECTION</span>
                                </div>
                                <div className='font-silkscreen text-[25px] sl:text-[30px] flex justify-between items-center gap-[15px]'>
                                    {isAuthenticated ? (
                                        <div className="flex items-center gap-[15px]">
                                            <FaTelegramPlane className='text-[#0099FE] w-[30px] h-[30px]' />
                                            <span className="text-[#0099FE] text-[25px] font-spline-sans-mono">
                                                {user?.username || 'Connected'}
                                            </span>
                                            {/* <div onClick={telegramLogout} className="hover:cursor-pointer">
                                                <img 
                                                    src="/assets/red-cancel.svg" 
                                                    className="w-[25px] h-[25px]"
                                                />
                                            </div> */}
                                        </div>
                                    ) : !WebApp.initData ? (
                                        <div className="w-full flex items-center justify-between text-[25px] sl:text-[30px] ">
                                            <span>VERIFY TELEGRAM</span>
                                            <FaTelegramPlane
                                                className='text-white w-[25px] h-[25px] hover:cursor-pointer'
                                                onClick={telegramLogin}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                                <div className='hover:cursor-pointer font-silkscreen text-[25px] sl:text-[30px]'>
                                    <span>DOMAINS</span>
                                </div>
                                <div className='hover:cursor-pointer font-silkscreen text-[25px] sl:text-[30px]'
                                    onClick={() => {
                                        toggleMenu();
                                        navigate('/leaderboard')
                                    }}
                                >
                                    <span>LEADERBOARD</span>
                                </div>
                                <div className='hover:cursor-pointer font-silkscreen text-[25px] sl:text-[30px]'>
                                    <span>COLLECTIONS</span>
                                </div>
                                <div className='hover:cursor-pointer font-silkscreen text-[25px] sl:text-[30px]'>
                                    <span>SETTINGS</span>
                                </div>
                                <div className='hover:cursor-pointer font-silkscreen text-[25px] sl:text-[30px]'>
                                    <span>HARVEST</span>
                                </div>
                                <div className='pt-[50px] pb-[20px] w-full flex items-center justify-center gap-[26px]'>
                                    <a href="https://t.me/SimpleNFT4all" aria-label="Telegram">
                                        <FaTelegramPlane className='text-white w-[33px] h-[28px]' />
                                    </a>
                                    <a href="https://x.com/SimpleNFT4all" aria-label="Twitter">
                                        <FaXTwitter className='text-white w-[28px] h-[27px]' />
                                    </a>
                                    <a href="https://discord.gg/kaWNH86FgY" aria-label="Discord">
                                        <FaDiscord className='text-white w-[39px] h-[28px]' />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </header>
        </>
    )
}

