import * as React from "react";
import PlusButton from "@/components/atomics/plus-button";
import MinusButton from "@/components/atomics/minus-button";

interface CollapsibleGroupProps {
    className?: string;
    extendedViewClassName?: string;
    collapsedViewClassName?: string;
    editTitle: string;
    viewTitle: string;
    isEditView: boolean;
    children: React.ReactNode;
}

export default function CollapsibleGroup({ editTitle, viewTitle, children, className, isEditView, extendedViewClassName, collapsedViewClassName }: CollapsibleGroupProps) {
    const [isEditOpen, setIsEditOpen] = React.useState(false);
    const [isViewOpen, setIsViewOpen] = React.useState(true);
    return (
        <div>
            {isEditView ? (
                <div className="flex flex-col pb-[22px]">
                    <div className={`flex justify-between items-center ${className}`}>
                        <span>{editTitle}</span>
                        {isEditOpen ?
                            <MinusButton
                                className="w-[23px] h-[23px] flex items-center justify-center"
                                onClick={() => setIsEditOpen(false)} /> :
                            <PlusButton
                                className="w-[23px] h-[23px] flex items-center justify-center"
                                onClick={() => setIsEditOpen(true)} />}
                    </div>
                    <div className={`
                        overflow-hidden transition-all duration-300 ease-in-out
                        ${isEditOpen ? 'opacity-100' : 'max-h-0 opacity-0'}
                    `}>
                        {children}
                    </div>
                </div>
            ) : (
                <>
                    <div className={`flex justify-between items-center ${isViewOpen ? extendedViewClassName : collapsedViewClassName}`}>
                        <span>{viewTitle}</span>
                        {isViewOpen ?
                            <MinusButton
                                className="w-[23px] h-[23px] flex items-center justify-center"
                                onClick={() => setIsViewOpen(false)} /> :
                            <PlusButton
                                className="w-[23px] h-[23px] flex items-center justify-center"
                                onClick={() => setIsViewOpen(true)} />}
                    </div>
                    <div className={`
                        overflow-hidden transition-all duration-300 ease-in-out
                        ${isViewOpen ? ' opacity-100' : 'max-h-0 opacity-0'}
                    `}>
                        {children}
                    </div>
                </>
            )}
        </div>
    )
}