import { useEffect, useState } from "react";
import {
  Address,
  beginCell,
  fromNano,
  OpenedContract,
  toNano,
} from "@ton/core";
import {
  CollectionMintParams,
  SimpleNftMaster,
} from "../contracts/SimpleNftMaster/tact_SimpleNftMaster";
import { useAsyncInitialize } from "./useAsyncInitialize";
import { useTonClient } from "./useTonCLient";
import { creationTonPrice, masterTonContract } from "../config";
import { useTonConnect } from "./useTonConnect";
import { getWrappedRoyaltyParams, makeCellForLink, UnwrappedRoyaltyParams } from "../utils/mint";
import { addCollection } from "../utils/storage";
import { backend } from "@/services/backend";

const sleep = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export interface CollectionMintData {
   collectionDataUrl: string;
   itemDataUrl: string;
   item_price: number;
   mint_limit: number;
   owner_address: string;
   royaltyParams: UnwrappedRoyaltyParams;
   mint_time_limit: number;
   is_sbt: boolean;
}

export function useMasterContract() {
  const { client } = useTonClient();
  const { wallet, sender } = useTonConnect();

  const masterContract = useAsyncInitialize(async () => {
    if (!client || !wallet) return;

    const contract: any = SimpleNftMaster.fromAddress(
      Address.parse(masterTonContract)
    );

    return client.open(contract) as OpenedContract<SimpleNftMaster>;
  }, [client, wallet]);

  return {
    contractAddress: masterContract?.address,
    collectionCreationPrice: creationTonPrice,
    newCollectionData: async () => {
      const index = await masterContract?.getGetNextCollectionIndex();
      await sleep(1101);
      console.log("Found index: ", index);
      const address = await masterContract?.getGetCollectionAddressByIndex(index || 0n);
      await sleep(1101);
      const strAddress = address?.toString();
      const result = {
        index,
        address: strAddress
      }
      return result;
    },
    mintCollection: async (data: CollectionMintData) => {
      return new Promise(async (resolve, reject) => {
        if (!wallet) {
          reject(new Error("Wallet not connected"));
          return;
        }
        try {
          if (data.item_price < 0.2) {
            throw new Error("Min price for item in collection is 0.2 TON")
          }
          const collectionContentCell = makeCellForLink(data.collectionDataUrl);
          const itemContentCell = makeCellForLink(data.itemDataUrl);
          const wrappedRoyaltyParams = getWrappedRoyaltyParams(data.royaltyParams)
          const index = await masterContract?.getGetNextCollectionIndex();
          await sleep(1101);
          const address = await masterContract?.getGetCollectionAddressByIndex(index || 0n);
          await sleep(1101);

          const mintCollectionDataObject = {
            $$type: "CollectionMintParams" as "CollectionMintParams",
            queryId: 0n, 
            owner_address: Address.parse(data.owner_address), 
            collection_content: collectionContentCell,
            nft_individual_content_url: itemContentCell,
            nft_price: toNano(String(data.item_price)),
            mint_limit: BigInt(data.mint_limit),
            mint_time_limit: BigInt(data.mint_time_limit),
            is_sbt: data.is_sbt ? 1n : 0n,
            royalty_params: wrappedRoyaltyParams
          }

          const result = await masterContract?.send(
            sender,
            {
              value: toNano(creationTonPrice),
            },
            mintCollectionDataObject
          );

          if (address) {
            addCollection(address.toString());
            try {
              backend.getCollectionData(address.toString())
            } catch (e) {
              console.log(e)
            }
            resolve({ success: true, address: address.toString(), transaction: result });
          } else {
            reject(new Error("Failed to get collection address"));
          }
        } catch (e) {
          console.error("Mint collection error:", e);
          reject(e);
        }
      });
    },
    withdraw: async (toAddress: string) => {
      return new Promise((resolve, reject) => {
        if (!wallet) {
          resolve(false);
          return;
        }

        masterContract
          ?.send(
            sender,
            {
              value: toNano("0.25"),
            },
            {
              $$type: "Withdraw",
              to: Address.parse(toAddress),
            }
          )
          .then((result) => {
            console.log("Result: ", result);
            resolve(true);
          })
          .catch((e) => {
            console.log("Error: ", e);
            resolve(false);
          });
      });
    },
  };
}
