import * as React from "react";
import CheckIcon from "./check-icon";
import { useEffect, useState } from "react";

interface InputType {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onHelpClick?: () => void;
  label?: string;
  essential?: boolean;
  type?: string;
  isLoading?: boolean;
  inputMode?: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';
  pattern?: string;
  includeAgreement?: boolean;
}

export default function Input({ 
  className, 
  placeholder, 
  value, 
  onChange, 
  onHelpClick,
  label, 
  essential, 
  type,
  inputMode,
  pattern,
  isLoading,
  includeAgreement
}: InputType) {
  
    const [isAgreed, setIsAgreed] = useState(false);
    const onClick = () => {
        setIsAgreed(!isAgreed);
    };
    
    return (
        includeAgreement ? (
            <div className={className}>
                <div className={`${isLoading || !isAgreed ? 'text-[#2B2B2B]' : ''} relative text-[24px] text-center pb-[10px]`}>
                    <div className={`absolute left-0 top-[7px] w-[24px] h-[24px] cursor-pointer  ${isAgreed ? 'bg-[#3B3B3B]' : 'bg-[#3B3B3B]'}`} onClick={onClick}>
                        {isAgreed && 
                            <CheckIcon 
                                className="absolute left-[0px] top-[-7px] w-[40px] h-[28px]" 
                                color={isLoading ? '#2B2B2B' : '#0099FE'} 
                                onClick={onClick}/>}
                    </div>
                    {essential && <span className={`${isLoading ? 'text-[#2B2B2B]' : 'text-[#FF0000]'}`}>*</span>}
                    {label}
                    {label && (
                        <div className="absolute right-0 top-0">
                            <span className={`${isLoading ? 'text-[#2B2B2B]' : 'text-[#0099FE]'}`} onClick={onHelpClick}>?</span>
                        </div>
                    )}
                </div>
                <input  
                    className={`${isLoading || !isAgreed ? 'cursor-not-allowed text-[#484848]' : ''} w-full h-[40px] bg-[#232323] p-[10px] outline-none text-center text-[20px] font-spline-sans-mono placeholder-[#606060]`} 
                    placeholder={placeholder} 
                    value={value} 
                    onChange={onChange}
                    disabled={isLoading || !isAgreed}
                    inputMode={inputMode}
                    pattern={pattern}
                    type={type}
                />
             </div>      
        ) : (
            <div className={className}>
                <div className={`${isLoading ? 'text-[#2B2B2B]' : ''} relative text-[24px] text-center pb-[10px]`}>
                    {essential && <span className={`${isLoading ? 'text-[#2B2B2B]' : 'text-[#FF0000]'}`}>*</span>}
                    {label}
                    {label && (
                        <div className="absolute right-0 top-0">
                            <span className={`${isLoading ? 'text-[#2B2B2B]' : 'text-[#0099FE]'}`} onClick={onHelpClick}>?</span>
                        </div>
                    )}
                </div>
                <input  
                    className={`${isLoading ? 'cursor-not-allowed text-[#484848]' : ''} w-full h-[40px] bg-[#232323] p-[10px] outline-none text-center text-[20px] font-spline-sans-mono placeholder-[#606060]`} 
                    placeholder={placeholder} 
                    value={value} 
                    onChange={onChange}
                    disabled={isLoading}
                    inputMode={inputMode}
                    pattern={pattern}
                    type={type}
                />
             </div>      
        )
    )
}
    