export const uploadButtonData = [
    {
        maxResolution:{width: 4080, height: 4080},
        maxFileSize:5,
        text:"COVER"
    },
    {
        maxResolution:{width: 4080, height: 4080},
        maxFileSize:2,
        text:"ICON"
    },
    {
        maxResolution:{width: 4080, height: 4080},
        maxFileSize:5,
        enableVideo: true,
        text:"NFT"
    }
]