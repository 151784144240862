import { LocalUserData, NftInfo } from "../types";

export const localStorageItemName = "USER_HISTORY";
export const storageUpdateEvent = "storageUpdate";
export const defaultStorage: LocalUserData = {
    wallets: [],
    nfts: [],
    collections: []
}

const updateEvent = new Event(storageUpdateEvent);

export function getUserAddressHistory(): LocalUserData {
    const data = localStorage.getItem(localStorageItemName);
    return data ? JSON.parse(data) : defaultStorage;
}

export function addWallet(wallet: string): void {
    const userData = getUserAddressHistory();
    if (!userData.wallets.includes(wallet)) {
        userData.wallets.push(wallet);
        localStorage.setItem(localStorageItemName, JSON.stringify(userData));
    }
    window.dispatchEvent(updateEvent)
}

export function addNft(nft: NftInfo): void {
    const userData = getUserAddressHistory();
    const exists = userData.nfts.some(existingNft => 
        existingNft.address === nft.address && existingNft.collection === nft.collection
    );
    if (!exists) {
        userData.nfts.push(nft);
        localStorage.setItem(localStorageItemName, JSON.stringify(userData));
    }
    window.dispatchEvent(updateEvent)
}

export function addCollection(collection: string): void {
    const userData = getUserAddressHistory();
    if (!userData.collections.includes(collection)) {
        userData.collections.push(collection);
        localStorage.setItem(localStorageItemName, JSON.stringify(userData));
    }
    window.dispatchEvent(updateEvent)
}

export function removeWallet(wallet: string): void {
    const userData = getUserAddressHistory();
    const updatedWallets = userData.wallets.filter(existingWallet => existingWallet !== wallet);
    userData.wallets = updatedWallets;
    localStorage.setItem(localStorageItemName, JSON.stringify(userData));
    window.dispatchEvent(updateEvent)
}

export function removeNft(nft: NftInfo): void {
    const userData = getUserAddressHistory();
    const updatedNfts = userData.nfts.filter(existingNft =>
        !(existingNft.address === nft.address && existingNft.collection === nft.collection)
    );
    userData.nfts = updatedNfts;
    localStorage.setItem(localStorageItemName, JSON.stringify(userData));
    window.dispatchEvent(updateEvent)
}

export function removeCollection(collection: string): void {
    const userData = getUserAddressHistory();
    const updatedCollections = userData.collections.filter(existingCollection => existingCollection !== collection);
    userData.collections = updatedCollections;
    localStorage.setItem(localStorageItemName, JSON.stringify(userData));
    window.dispatchEvent(updateEvent)
}

export const generateBlackSquare = (width: number, height: number, returnAsFile: boolean = false): string | File => {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, width, height);
    }
    
    const imageDataUrl = canvas.toDataURL("image/png");
    
    if (returnAsFile) {
      return new File([canvasToBlob(canvas)], "black_square.png", { type: "image/png" });
    }
    return imageDataUrl;
  };
  
  const canvasToBlob = (canvas: HTMLCanvasElement): Blob => {
    const dataUrl = canvas.toDataURL("image/png");
    const byteString = atob(dataUrl.split(",")[1]);
    const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([uint8Array], { type: mimeString });
  };;

  export const getVideoFrame = (videoInput: string | File, seekTime = 0.1, returnAsFile = false): Promise<string | File> => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.crossOrigin = "anonymous";
      video.muted = true;
      video.preload = "metadata";
  
      const loadVideo = (src: string) => {
        video.src = src;
        video.onloadeddata = () => {
          video.currentTime = seekTime;
        };
      };
  
      if (typeof videoInput === "string") {
        loadVideo(videoInput);
      } else {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
          if (event.target?.result) {
            loadVideo(event.target.result as string);
          } else {
            resolve(generateBlackSquare(256, 256, returnAsFile));
          }
        };
        fileReader.onerror = () => resolve(generateBlackSquare(256, 256, returnAsFile));
        fileReader.readAsDataURL(videoInput);
      }
  
      video.onseeked = () => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth || 512;
        canvas.height = video.videoHeight || 512;
        const ctx = canvas.getContext("2d");
  
        if (ctx) {
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const imageDataUrl = canvas.toDataURL("image/png");
          
          if (returnAsFile) {
            fetch(imageDataUrl)
              .then(res => res.blob())
              .then(blob => resolve(new File([blob], "frame.png", { type: "image/png" })))
              .catch(() => resolve(generateBlackSquare(256, 256, returnAsFile)));
          } else {
            resolve(imageDataUrl);
          }
        } else {
          resolve(generateBlackSquare(256, 256, returnAsFile));
        }
      };
  
      video.onerror = () => resolve(generateBlackSquare(256, 256, returnAsFile));
    });
  };;

export async function imageVideoPreloader (url: string): Promise<{
    mimeType: string;
    file: Buffer;
    frame?: string;
}> {
    const response = await fetch(url);
    
    if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.status} ${response.statusText}`);
    }
    
    const mimeType = response.headers.get("content-type") || "unknown";
    const file = Buffer.from(await response.arrayBuffer());

    if (mimeType.startsWith("video/")) {
        return new Promise((resolve, reject) => {
             getVideoFrame(url).then((frame: string) => {
                resolve({ mimeType, file, frame }); 
             }).catch((e) => {
                console.log(e)
                const frame = generateBlackSquare(256, 256, false);
                return { mimeType, file, frame };
             })
        })
    }
    
    return { mimeType, file };
}

