import * as React from "react";
import CancelButton from "./cancel-button";
import { useSnackbar } from "notistack";
import { getVideoFrame } from "@/utils/storage";

type Props = {
    className?: string;
    maxResolution?: { width: number; height: number };
    maxFileSize?: number; 
    text?: string;
    enableVideo?: boolean;
    onUpload?: (file: File) => void;
    onRemove?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function UploadButton({ 
    className,
    maxResolution, 
    maxFileSize,
    text,
    enableVideo,
    onUpload,
    onRemove
}: Props) {
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [selectedImage, setSelectedImage] = React.useState<string | null>(null);
    const [uploadMimeType, setMimeType] = React.useState<string>();
    const { enqueueSnackbar } = useSnackbar();

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        // Check file size (convert maxFileSize from MB to bytes)
        if (maxFileSize && file.size > maxFileSize * 1024 * 1024) {
            enqueueSnackbar(`File size must be less than ${maxFileSize}MB`, { 
                variant: 'error', 
                autoHideDuration: 1000 
            });
            return;
        }

        // Store MIME type
        setMimeType(file.type);

        if (file.type.startsWith('video/') && enableVideo) {
            try {
                const thumbnail = (await getVideoFrame(file, 1, false)) as string;
                setSelectedImage(thumbnail);
            } catch (error) {
                enqueueSnackbar('Failed to generate video thumbnail', { 
                    variant: 'error', 
                    autoHideDuration: 1000 
                });
                return;
            }
        } else {
            setSelectedImage(URL.createObjectURL(file));
        }

        onUpload?.(file);
    };

    const handleCancelClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        
        if (selectedImage) {
            URL.revokeObjectURL(selectedImage);
            setSelectedImage(null);
            setMimeType(undefined);
            onRemove?.();
        }
    };

    return (
        <div className={`${className}`}>
            <div className="relative hover:cursor-pointer w-full aspect-square flex justify-center items-center hover:scale-105 transition-transform duration-300 ease-in-out" 
                onClick={() => { fileInputRef.current?.click(); }}>
                {selectedImage ? (
                    <>
                        <img 
                            src={selectedImage} 
                            alt="Uploaded preview" 
                            className="w-full h-full object-cover opacity-[40%]"
                        /> 
                        <div className="absolute top-0 left-0 w-full h-full">
                            <img src="/assets/avatar-bg.png" alt="Background" />
                        </div>
                        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                            <img src="/assets/check-icon.svg" alt="Check Icon" />
                        </div>
                        <CancelButton 
                            className="absolute top-[-12px] right-[-12px]"
                            color="#FF0000"
                            onClick={(e) => handleCancelClick(e)}
                        />
                    </>
                ) : (
                    <>
                        <img src="/assets/upload-button.svg" alt="Upload Button" />
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept={enableVideo ? "video/*, image/*" : "image/*"}
                            className="hidden"
                            onChange={handleFileUpload}
                        />
                        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center items-center">
                            <div className="w-[46px] h-[41px]">
                                <img src="/assets/upload-icon.svg" alt="Upload Icon" />
                            </div>
                            <div className="flex flex-col items-center text-[13px] font-spline-sans-mono pt-[5px]">
                                <span className="line-height-1">{maxResolution?.width}x{maxResolution?.height}</span>
                                <span className="line-height-1"> {maxFileSize ? `<${maxFileSize}MB` : ''}</span>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div className="flex justify-center items-center text-[20px] pt-[10px]">
                <span className={`text-sm ${selectedImage ? 'text-[#484848]' : ''}`}>{text}</span>
            </div>
        </div>
    )
}