import React from "react";
import { CollectionMasterDataType, OwnerNftData } from "@/types";
import { useEffect, useState } from "react";
import { fetchData, CollectionDataCache, OwnerCollectionData } from "@/types/collection";
import { backend } from "@/services/backend";

interface CollectionSkinCardProps {
    title: string;
    className?: string;
    item: any;
    onClick?: () => void;
}

const CollectionSkinCard = ({ title, className, item, onClick }: CollectionSkinCardProps) => {
    const [metadata, setMetadata] = useState<any | null>(null);

    useEffect(() => {
        if (item?.data_url) {
            fetchMetadata(item.data_url);
        }
    }, [item?.data_url]);

    const fetchMetadata = async (collectionUrl: string) => {
        try {
            const collectionResponse = await fetch(collectionUrl);
            const collectionData = await collectionResponse.json();
            setMetadata(collectionData);
        } catch (error) {
            console.error('Error fetching metadata:', error);
        }
    };


    return (
        <div className={`${className} flex flex-col items-center justify-center cursor-pointer ${title === 'BACKGROUND' ? 'w-full h-[132px]' : ''}`} onClick={onClick}>
            <img src={title === 'AVATAR' ? metadata?.image : metadata?.cover_image || ''} className="object-contain" />
            <div className="flex flex-col w-full">
                <div className="flex items-center justify-center">
                    {metadata?.name && metadata?.name.length > 7 ? (
                        <>
                            <span className="text-[12px]">{metadata?.name.slice(0, -3)}..</span>
                            <span className="text-[12px]">{metadata?.name.slice(-3)}</span>
                        </>
                    ) : (
                        <p className="text-[12px]">{metadata?.name || 'UNKNOWN'}</p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CollectionSkinCard;