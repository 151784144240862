import * as React from "react";
import Direction from "@/components/atomics/direction";
import StepLoad from "@/components/atomics/step-load";
import UploadButton from "@/components/atomics/upload-button";
import Button from "@/components/atomics/button";
import { uploadButtonData } from "@/data/upload-button";
import FirstStepHelp from "@/components/atomics/first-step-help";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "@/config";
import { IpfsUrls, useCollection } from '@/context/CollectionContext';
import MotionModal from "@/components/atomics/motion-modal";
import { IoCheckmarkSharp } from "react-icons/io5";
import { getVideoFrame } from "@/utils/storage";

export default function CreateCollectionFirstStep() {
    const navigate = useNavigate();    
    const { ipfsUrls, setIpfsUrls } = useCollection();
    const [imageMimeType, setImageMimeType] = React.useState<string>();
    const [previewImage, setPreviewImage] = React.useState<string | null>(null);
    const [uploadedFiles, setUploadedFiles] = React.useState<{
        cover: File | null;
        icon: File | null;
        nft: File | null;
        content: File | null;
    }>({
        cover: null,
        icon: null,
        nft: null,
        content: null
    });
 
    const isAllUploaded = React.useMemo(() => {
        const { cover, icon, nft, content } = uploadedFiles;
        return cover !== null && 
               icon !== null && 
               (nft !== null || content !== null);
    }, [uploadedFiles]);

    const [showHelp, setShowHelp] = React.useState(false);

    const [ipfsUrl, setIpfsUrl] = React.useState(ipfsUrls || {
        cover: null,
        icon: null,
        nft: null,
        content: null
    });

    const [isLoading, setIsLoading] = React.useState(false);
    const [isUploaded, setIsUploaded] = React.useState(false);

    const handleRemoveFile = (item:any) => {
        setUploadedFiles(prev => ({
            ...prev,
            [item.text.toLowerCase()]: null
        }))
        setIsUploaded(false);
        
    }

    const handleFileUpload = async (file: File, type: string) => {
        if (type.toLowerCase() === 'nft') {
            setImageMimeType(prev => prev || file.type);
            if (file.type=== "video/mp4") {
               const videoFrame = await getVideoFrame(file, 1, true);
               if (typeof videoFrame === "string") return;
               // Upload preview as a picture
               handleFileUpload(videoFrame, 'nft');
               setUploadedFiles(prev => ({
                ...prev,
                content: file
               }));
               return;
            }
        }
        setUploadedFiles(prev => ({
            ...prev,
            [type.toLowerCase()]: file
        }));
    };

      const fileBackendLoad = async () => {
        if (!uploadedFiles.cover || !uploadedFiles.icon || !(uploadedFiles.nft || uploadedFiles.content)) {
           console.log("Please upload all files");
            return;
        }
        setIsLoading(true);
        setIsUploaded(false);
        
        try {
            const formData = new FormData();
            // Add files in specific order
            console.log(uploadedFiles);
            formData.append("files", uploadedFiles.cover, "collectionBackgroundUrl");
            formData.append("files", uploadedFiles.icon, "collectionPhotoUrl");
            if(uploadedFiles.nft){
                formData.append("files", uploadedFiles.nft, "itemPhotoUrl");
            }
            if (uploadedFiles.content) {
                formData.append("files", uploadedFiles.content, "videoContent");
            }

            console.log(formData);
            for (let pair of formData.entries()) {
                console.log(pair[0], pair[1]); // This will show each file entry
            }
            const response = await fetch(`${BACKEND_URL}files/loadphotos`, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Files upload failed");
            }

            const result = await response.json();
            const urls = result.urls;

            if (urls.length >= 3) {
                const newIpfsUrls = {
                    cover: urls[0],
                    icon: urls[1],
                    nft: urls[2],
                    content: urls[3],
                    nftMimeType: imageMimeType,

                };
                setIpfsUrl(newIpfsUrls);
                setIpfsUrls(newIpfsUrls);
                setIsUploaded(true);
            } else {
                throw new Error("Not all URLs returned from the server");
            }

        } catch (error) {
            console.error("Error uploading files:", error);
            setIsUploaded(false);
            setIsLoading(false);
            return;
        }
        
        setIsLoading(false);
    };


    return (
        <div className="w-full flex flex-col justify-center items-center pt-[20px] pl-[30px] pr-[30px] pb-[70px] relative">    
            <MotionModal isOpen={showHelp}>
                <FirstStepHelp 
                    className="w-full bg-black"
                    onClose={() => setShowHelp(false)} 
                    onOk={() => setShowHelp(false)}
                /> 
            </MotionModal>
            <header className="flex flex-col justify-center text-[32px] text-center">
                <div className="relative">
                    <span>STEP_1</span>
                    <div 
                        className="absolute top-[50%] left-[calc(50%+85px)] translate-x-[-50%] translate-y-[-50%] text-[#0099FE] hover:cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out"
                        onClick={() => setShowHelp(true)}
                    >
                        <span>?</span>
                    </div>
                </div>
                <span> UPLOAD FILES</span>          
            </header> 
            <div className="w-full flex justify-center pt-[22px] gap-[20px]">
                {uploadButtonData.map((item, index) => (
                    <UploadButton 
                        key={index}
                        className={`w-full ${isLoading ? 'pointer-events-none' : ''}`}
                        maxResolution={{width: item.maxResolution.width, height: item.maxResolution.height}}
                        maxFileSize= {item.maxFileSize}
                        enableVideo={item.enableVideo}
                        text={item.text}
                        onUpload={(file) => handleFileUpload(file, item.text)}
                        onRemove={() => handleRemoveFile(item) }
                    />
                ))}
            </div>
           <Button 
                className={`${isAllUploaded && !isLoading && !isUploaded ? 'hover:scale-105 transition-transform duration-300 ease-in-out': ''}  'w-[64%] mt-[20px] mb-[20px] text-[24px]`} 
                image={isAllUploaded ? "/assets/button.svg" : "/assets/disable-button.svg"}     
                text={isLoading ? "LOADING" : isUploaded ? "LOADED!" : "LOAD DATA"}
                onClick={fileBackendLoad}
                disabled={!isAllUploaded || isLoading || isUploaded}
                isLoading={isLoading}/>
                
            <div className="flex flex-col justify-center mb-[14px] space-y-4">
                {['cover', 'icon', 'nft'].map((type) => (
                    <div key={type} className={`flex flex-col justify-center items-center text-center ${isUploaded ? 'text-white' : 'text-[#0A0A0A]'}`}>
                        <div className="w-full relative text-[24px] uppercase">
                            {type} url
                            {ipfsUrl[type] && <img src="/assets/check-icon.svg" alt="Check Icon" className="absolute top-[4px] left-[15px] w-[24px] h-[24px]"/>}
                        </div>
                        <div className={`text-[16px] font-spline-sans-mono transition-all duration-300`}>
                            {ipfsUrl[type] ? ipfsUrl[type].slice(0, 28) + '...' : 'N/A'}
                        </div>
                    </div>
                ))}
            </div>
            <StepLoad currentIndex={0} totalIndex={3} />
            <div 
                className="w-full relative flex justify-center items-center mt-[14px]"
            >
               <span 
                 className={`text-[${Object.values(ipfsUrls).some(url => url !== null) ? '#0099FE' : '#2B2B2B'}]  text-[32px]`}
                 onClick={() => Object.values(ipfsUrls).some(url => url !== null) && navigate('/collection/create/2')}
                > 
                    NEXT 
                </span>
               <div 
               className="absolute top-[50%] right-[60px] translate-y-[-50%] w-[17px] h-[29px] text-[#0099FE]"
               onClick={() => Object.values(ipfsUrls).some(url => url !== null) && navigate('/collection/create/2')}
               >
                    <Direction 
                        className="w-full h-full"
                        color={Object.values(ipfsUrls).some(url => url !== null) ? "#0099FE" : "#2B2B2B"} />
                </div>
            </div>
        </div>
    )
} 

