import * as React from "react";
import { useEffect, useState } from "react";
import { NFT } from "@/types/collectionType";
import { useNavigate, useParams } from "react-router-dom";
import { useWallet } from "@/context/WalletContext";
import ShareUrl from "@/components/atomics/share-url";
import Button from "@/components/atomics/button";
import ContractCard from "@/components/atomics/contract-card";
import { imageVideoPreloader } from "@/utils/storage";
import {
  CollectionDataCache,
  CollectionFileData,
  ItemDataType,
  ItemFileData,
} from "@/types";

interface NftProps {
  nftData: NFT;
  itemInfo: ItemDataType | null;
  nftContent: ItemFileData | null;
  collectionData: CollectionDataCache | null;
}

export default function Nft({
  nftData,
  itemInfo,
  nftContent,
  collectionData,
}: NftProps) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { walletAddress } = useWallet();

  const [collectionInfo, setCollectionInfo] =
    useState<CollectionFileData | null>(null);
  const [imageMimeType, setImageMimeType] = useState<string>();
  const [isIOS, setIsIOS] = useState<boolean>(false);
  const [videoRef, setVideoRef] = useState<HTMLVideoElement | undefined>(undefined);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
        if (!collectionData?.data_url) return;
        
        try {
            const response = await fetch(collectionData.data_url);
            const data = await response.json();
            setCollectionInfo(data);
        } catch (error) {
            console.error('Failed to fetch collection info:', error);
            setCollectionInfo(null);
        }
    }
    fetchData();
}, [collectionData]);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    setIsIOS(/iPad|iPhone|iPod/.test(userAgent));
  }, []);

  useEffect(() => {
    if (videoRef && isIOS) {
      videoRef.muted = true; // Убеждаемся, что видео выключено
      /* videoRef.play().catch(() => {
        console.log("Autoplay blocked on iOS, waiting for user interaction.");
      }); */
    }
  }, [videoRef, isIOS]);

  useEffect(() => {
    if (nftContent?.image) {
      imageVideoPreloader(nftContent?.content_url || nftContent.image)
        .then((data) => {
          setImageMimeType(data.mimeType || "image/png");
          if (data.mimeType.startsWith("video/")) {
            setPreviewImage(data.frame || `${nftContent?.content_url || nftContent.image}?preview=true`);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [nftContent]);

  useEffect(() => {
    if (isIOS && videoRef) {
      // Добавляем обработчик клика, чтобы iOS разрешил воспроизведение
      const handleClick = () => {
        videoRef.play();
      };
      videoRef.addEventListener("click", handleClick);

      return () => {
        videoRef.removeEventListener("click", handleClick);
      };
    }
  }, [isIOS, videoRef]);

  /* if (!imageMimeType) {
    return(
        <div className="min-h-[100vh] flex justify-center items-center text-[30px]">Loading...</div>
    )
  } */

  const renderMetadata = () => {
      if (!nftContent?.attributes?.length) return null;
      
      return nftContent.attributes.map((item, index) => (
          <div 
              key={`metadata-${index}`} 
              className="border-b-2 border-[#757575] border-solid flex justify-between pt-[5px]"
          >
              <p className="w-[50%] text-[13px] text-[#757575] font-spline-sans-mono">
                  {item.trait_type}
              </p>
              <p className="w-[50%] text-[13px] text-[#757575] font-spline-sans-mono">
                  {item.value}
              </p>
          </div>
      ));
  };

  const videoPack = () => (
    <video
      className="w-full h-full object-cover"
      controls
      autoPlay
      playsInline
      muted
      loop
      poster={nftContent?.image}
      onLoadedData={() => setIsVideoLoaded(true)}
    >
      <source src={nftContent?.content_url || nftContent?.image} type="video/mp4" />
    </video>
  );

  return (
    <article className="nft-container">
      <div className="w-full aspect-square relative">
        {!imageMimeType || imageMimeType.startsWith("video/") ? videoPack() : (
          <img
            src={nftContent?.image}
            alt={nftContent?.name || "NFT Image"}
            className="w-full h-full object-cover"
          />
        )}
        <ShareUrl
          className="absolute bottom-[-49px] right-[28px] z-[1] hover:cursor-pointer"
          buttonColor="#0099FE"
          path={`nft/${id}`}
          walletAddress={walletAddress}
        />
      </div>
      {/* Остальная разметка остается неизменной */}
      <div className="px-[30px] pt-[20px]">
            <h4 className="text-[32px] max-w-[70%] truncate">
                {nftContent?.name}
            </h4>
            <h5 className="text-[36px] text-[#0099FE]">
                #{itemInfo?.index}
            </h5>
            <div>
                <a 
                    className="text-[16px] underline cursor-pointer"
                    onClick={() => itemInfo?.collection_address && navigate(`/collection/${itemInfo.collection_address}`)}
                >
                    COLLECTION:
                    <span className="text-[16px] font-spline-sans-mono ml-2">
                        {collectionInfo?.name || 'Loading...'}
                    </span>
                </a>
            </div>
            
            <ContractCard 
                className="pt-[10px] pb-[10px] justify-start"
                text="CONTRACT" 
                length={10}
                address={itemInfo?.collection_address || ""} 
                onClick={() => navigate(`/collection/${itemInfo?.collection_address}`)}
            />
            <ContractCard 
                className="pt-[10px] pb-[10px]"
                length={14}
                text="OWNER" 
                address={itemInfo?.owner_address || ""} 
                onClick={() => navigate(`/owner/${itemInfo?.owner_address}`)}
            />
            
            <section className="metadata-section">
                <p className="text-[24px]">DESCRIPTION:</p>
                <p className="text-[13px] font-spline-sans-mono text-[#757575]">
                    {nftContent?.description || 'No description available'}
                </p>
                
                <div className="pt-[20px]">
                    <p className="text-[24px]">METADATA:</p>
                    {renderMetadata()}
                </div>
            </section>
            
            <div className="flex justify-center">
                <Button 
                    className="w-[calc(100%-40px)] text-[24px] mt-[34px]"
                    image="/assets/button.svg"
                    text="GO TO COLLECTION" 
                    onClick={() => itemInfo?.collection_address && navigate(`/collection/${itemInfo.collection_address}`)}
                />
            </div>
        </div>
    </article>
  );
}