export const inputData = [
    {
        label: "MINT LIMIT",
        placeholder: "0",
        value: '0',
        essential: false,
        type: "number"
    },
    {
        label: "TIME LIMIT",
        placeholder: "0",
        value: '0',
        essential: false,
        type: "datetime"
    },
    {
        label: "ROYALTY_%",
        placeholder: "0",
        value: '0',
        essential: false,
        type: "number"
    },
    {
        label: "PRICE IN TON",
        placeholder: "0",
        value: '0',
        essential: true,
        type: "number"
    },

]