import {
  CollctionDataType,
  CollectionMasterDataType,
  CollectionDataCache,
  ItemDataType,
  MasterDataType,
  OwnerData,
  ItemFileData,
} from "../types";
import { BACKEND_URL } from "../config";
import { TelegramInitData } from "@/types/telegram";
import {
  Account,
  ConnectAdditionalRequest,
  TonProofItemReplySuccess,
} from "@tonconnect/sdk";
import { LSService } from "./storage";

export type PhotoCategories = "avatar" | "cover";

export interface SocialItem {
  name: string;
  link: string;
}

export interface RoyaltyParams {
  numerator: number;
  denominator: number;
  destination: string;
}

export interface OwnerFileItem {
  id: number;
  url: string;
  category: PhotoCategories;
}

interface ReferralStats {
  referrals: number;
  collections: number;
  total_earned: number;
}

interface ReferralCustomParams {
  referral_percent: number;
  custom_name?: number;
}

interface ItemInCollection {
  address: string;
  index: number;
}

interface IncomeData {
  turnower: number;
  ref_turnower: number;
  comissions: number;
  ref_comissions: number;
}

const defaultIncomeData: IncomeData = {
  turnower: 0,
  ref_turnower: 0,
  comissions: 0,
  ref_comissions: 0,
};

interface CollectionTrackData {
  address: string; 
  category_id: number;
}

class BackendService {
  private headers: Record<string, string> = {};

  private static instance: BackendService;

  constructor(headers: Record<string, string>) {
    this.headers = headers;
  }

  private setAccessToken(accessToken: string) {
    this.headers.Authorization = `${accessToken}`;
  }

  static getInstance(): BackendService {
    if (!BackendService.instance) {
      BackendService.instance = new BackendService({});
      const bearerToken = localStorage.getItem("bearerToken");
      if (bearerToken) {
        BackendService.instance.setAccessToken(bearerToken);
      }
    }
    return BackendService.instance;
  }

  private async get(path: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = `${BACKEND_URL}${path}`;

        const res = await fetch(endpoint, {
          method: "GET",
        });
        const json: any = await res.json();
        resolve(json);
      } catch (e: any) {
        console.log(Error(e));
        reject(e);
      }
    });
  }

  private async post<T>(
    path: string,
    body: T | FormData,
    method: "POST" | "PUT" | "PATCH"
  ) {
    try {
      const endpoint = `${BACKEND_URL}${path}`;
      const headers: HeadersInit = {
        Accept: "application/json",
      };

      // Only add Content-Type for JSON bodies, not FormData
      if (!(body instanceof FormData)) {
        headers["Content-Type"] = "application/json";
      }
      const token = LSService.getTonAuthToken();

      const bodyToSend =
        body instanceof FormData
          ? (() => {
              body.append("token", String(token));
              return body;
            })()
          : { ...body, token };
      const response = await fetch(endpoint, {
        method: method,
        mode: "cors",
        // credentials: "include",
        body:
          bodyToSend instanceof FormData
            ? bodyToSend
            : JSON.stringify({ ...body, token }),
        headers: new Headers(headers),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(json?.error);
      }

      return await response.json();
    } catch (e: any) {
      throw new Error(e);
    }
  }

  public async getItemData(address: string): Promise<ItemDataType | null> {
    return new Promise((resolve) => {
      this.get(`item/${address}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

  public async getCollectionData(
    address: string
  ): Promise<CollectionDataCache | null> {
    return new Promise((resolve) => {
      this.get(`collectiondata/${address}`)
        .then((result) => {
          resolve(result.collection);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

  public async setUserDisplayName(username: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.post(
        "usernameset",
        {
          username,
        },
        "POST"
      )
        .then((result) => {
          resolve(!!result.success);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  }

  public async getUserDisplayName(address: string): Promise<string | null> {
    return new Promise((resolve) => {
      this.get(`usernameget/${address}`)
        .then((result) => {
          resolve(result.username);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

  public async deleteOwnerPhoto (photoIds: number[]): Promise<boolean> {
    return new Promise((resolve) => {
      this.post(
        "/files/delete",
        {
          deletions: photoIds
        },
        "POST"
      )
        .then((result) => {
          resolve(!!result?.success);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  }

  public async checkWalletRights(
    proof: TonProofItemReplySuccess["proof"],
    account: Account
  ): Promise<string | null> {
    return new Promise((resolve) => {
      this.post(
        "proofwallet",
        {
          address: account.address,
          proof: {
            address: account.address,
            network: account.chain,
            public_key: account.publicKey,
            proof: {
              ...proof,
              state_init: account.walletStateInit,
            },
          },
        },
        "POST"
      )
        .then((result) => {
          resolve(result.success ? result.token : null);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

  public async checkToken(token?: string) {
    const authToken = token || LSService.getTonAuthToken();
    if (!authToken) return false;
    return new Promise((resolve) => {
      this.post(
        "validatetoken",
        {
          token: authToken,
        },
        "POST"
      )
        .then((result) => {
          resolve(!!result.address);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  }

  public async generatePayload(): Promise<ConnectAdditionalRequest | null> {
    return new Promise((resolve) => {
      this.get(`payloadtoken`)
        .then((result) => {
          resolve({ tonProof: result.payload as string });
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

  public async getOwnerData(address: string): Promise<OwnerData | null> {
    return new Promise((resolve) => {
      this.get(`owner/${address}`)
        .then((result) => {
          resolve(result);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

  public async getItemsInCollection(data: {
    collectionAddress: string;
    page: number;
    perPage: number;
  }): Promise<ItemInCollection[]> {
    return new Promise((resolve) => {
      const {collectionAddress, page, perPage } = data
      this.get(`collectionitems/${collectionAddress}/${perPage}/${page}`)
        .then((result) => {
          resolve(result.items);
        })
        .catch((e) => {
          console.log(e);
          resolve([]);
        });
    });  
  }

  public async getMasterData(address: string): Promise<MasterDataType | null> {
    return new Promise((resolve) => {
      this.get(`master/${address}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

  public async getCollectionMasterData(
    address: string
  ): Promise<CollectionMasterDataType | null> {
    return new Promise((resolve) => {
      this.get(`collectionmaster/${address}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

  public async getCollectionRoyaltyParams(
    address: string
  ): Promise<RoyaltyParams | null> {
    return new Promise((resolve) => {
      this.get(`collectionroyalty/${address}`)
        .then((result) => {
          resolve(result.royalty);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

  public async getDeployStatus(address: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.get(`isdeploy/${address}`)
        .then((result) => {
          resolve(result.deploy || false);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  }

  public async registerReferral(
    address: string,
    referral: string
  ): Promise<boolean> {
    return new Promise((resolve) => {
      this.get(`referralsave/${address}/${referral}`)
        .then((result) => {
          resolve(result.success || false);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  }

  public async createCustomReferralLink(
    address: string
  ): Promise<string | null> {
    return new Promise((resolve) => {
      this.get(`referrallink/${address}`)
        .then((result) => {
          resolve(result.link || null);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

  public async getWalletCollectionBalance(
    wallet: string,
    collection: string
  ): Promise<number> {
    return new Promise((resolve) => {
      this.get(`collectionbalance/${wallet}/${collection}`)
        .then((result) => {
          resolve(result.balance || 0);
        })
        .catch((e) => {
          console.log(e);
          resolve(0);
        });
    });
  }

  public async getReferralCustomParams(
    address: string
  ): Promise<ReferralCustomParams> {
    return new Promise((resolve, reject) => {
      this.get(`referralcustom/${address}`)
        .then((result) => {
          resolve(result);
        })
        .catch((e) => {
          console.log(e);
          reject("Failed to get data");
        });
    });
  }

  public async getReferralStats(address: string): Promise<ReferralStats> {
    return new Promise((resolve, reject) => {
      this.get(`referralstats/${address}`)
        .then((result) => {
          resolve(result);
        })
        .catch((e) => {
          console.log(e);
          reject("Failed to get data");
        });
    });
  }

  public async getAddressByDomain(domain: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.get(`domainwallet/${domain}`)
        .then((result) => {
          resolve(result.address);
        })
        .catch((e) => {
          console.log(e);
          reject("Failed to get data");
        });
    });
  }

  public async getReferral(address: string): Promise<string | null> {
    return new Promise((resolve) => {
      this.get(`referralget/${address}`)
        .then((result) => {
          resolve(result.referral);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });
  }

  public async saveTelegram(
    telegramInitData: string,
    wallet: string
  ): Promise<boolean> {
    return new Promise((resolve) => {
      this.post(
        "user/telegramattach",
        {
          telegramInitData,
          wallet,
        },
        "POST"
      )
        .then((result) => {
          resolve(result.success);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  }

  public async getAllLastCollections(): Promise<CollectionDataCache[]> {
    return new Promise((resolve) => {
      this.get("home/lastcollections")
        .then((result) => {
          resolve(result.collections);
        })
        .catch((e) => {
          console.log(e);
          resolve([]);
        });
    });
  }

  public async getWalletDescription(address: string): Promise<string> {
    return new Promise((resolve) => {
      this.get(`description/${address}`)
        .then((result) => {
          resolve(result.description);
        })
        .catch((e) => {
          console.log(e);
          resolve("");
        });
    });
  }

  public async updateWalletDescription(data: {
    address: string;
    text: string;
  }): Promise<boolean> {
    return new Promise((resolve) => {
      this.post("updatedescription", data, "POST")
        .then((result) => {
          resolve(result.success);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  }

  public async getOwnerPhotos(address: string): Promise<OwnerFileItem[]> {
    return new Promise((resolve) => {
      this.get(`user/ownerphotos/${address}`)
        .then((result) => {
          resolve(result.files);
        })
        .catch((e) => {
          console.log(e);
          resolve([]);
        });
    });
  }

  public async uploadOwnerPhoto(
    file: File,
    address: string,
    category: PhotoCategories
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // telegramInitData, address, category
      const formData = new FormData();
      formData.append("file", file);
      formData.append("address", address);
      formData.append("category", category);

      this.post("files/loaduserphoto", formData, "POST")
        .then((res) => {
          resolve(!!res.success);
        })
        .catch((e) => {
          console.log(e);
          reject(e.error || e);
        });
    });
  }

  public async setUserSocials(data: {
    address: string;
    socials: SocialItem[];
  }) {
    return new Promise((resolve, reject) => {
      this.post("user/updatesocials", data, "POST")
        .then((res) => {
          resolve(!!res.success);
        })
        .catch((e) => {
          console.log(e);
          reject(e.error || e);
        });
    });
  }

  public async getUserSocials(address: string): Promise<SocialItem[]> {
    return new Promise((resolve) => {
      this.get(`user/socials/${address}`)
        .then((result) => {
          resolve(result.socials);
        })
        .catch((e) => {
          console.log(e);
          resolve([]);
        });
    });
  }

  public async getUserRevenue (address: string): Promise<number> {
    return new Promise((resolve) => {
      this.get(`turnower/${address}`)
        .then((result) => {
          resolve(result.value);
        })
        .catch((e) => {
          console.log(e);
          resolve(0);
        });
    });   
  }

  public async getUserReferralRevenue (address: string): Promise<number> {
    return new Promise((resolve) => {
      this.get(`referralturnower/${address}`)
        .then((result) => {
          resolve(result.value);
        })
        .catch((e) => {
          console.log(e);
          resolve(0);
        });
    });   
  }

  public async getUserMarketIncome (address: string): Promise<number> {
    return new Promise((resolve) => {
      this.get(`marketincome/${address}`)
        .then((result) => {
          resolve(result.value);
        })
        .catch((e) => {
          console.log(e);
          resolve(0);
        });
    });   
  }

  public async getUserReferralMarketIncome (address: string): Promise<number> {
    return new Promise((resolve) => {
      this.get(`referralmarketincome/${address}`)
        .then((result) => {
          resolve(result.value);
        })
        .catch((e) => {
          console.log(e);
          resolve(0);
        });
    });   
  }

  public async getUserAllFinanceData (address: string): Promise<IncomeData> {
    return new Promise((resolve) => {
      this.get(`allfinance/${address}`)
        .then((result) => {
          resolve(result.values);
        })
        .catch((e) => {
          console.log(e);
          resolve(defaultIncomeData);
        });
    });   
  }

  public categoryName (id: number) {
   const names = [{
      id: 1,
      name: "background"
   }, {
    id: 2,
    name: "avatar"
   }]
   return names.find(item => item.id === id)?.name
  }

  public async getTrackingCollcetions(): Promise<CollectionTrackData[]> {
    return new Promise((resolve) => {
      this.get(`skins/all`)
        .then((result) => {
          resolve(result.collections);
        })
        .catch((e) => {
          console.log(e);
          resolve([]);
        });
    });      
  }

  public async getAvailableTrackingCollcetions(owner: string): Promise<CollectionTrackData[]> {
    return new Promise((resolve) => {
      this.get(`skins/available/${owner}`)
        .then((result) => {
          resolve(result.collections);
        })
        .catch((e) => {
          console.log(e);
          resolve([]);
        });
    });      
  }
  // returning nft address
  public async getSelectedSkinNft (owner: string, category: "background" | "avatar" = "background"): Promise<string | null> {
    return new Promise((resolve) => {
      const category_id = category === "background" ? 1 : 2;
      this.get(`userskin/${category_id}/${owner}`)
        .then((result) => {
          resolve(result.item);
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });     
  }

  public async getNftContentByDataUrl (url: string): Promise<ItemFileData | null> {
      return new Promise((resolve) => {
        fetch(url)
        .then((res) => res.json())
        .then((res: ItemFileData) => {
          resolve(res)
        }).catch(() => {
          resolve(null)
        })
      })
  }
  // returning pure image
  public async getSelectedSkinNftFile (owner: string, category: "background" | "avatar" = "background"): Promise<string | null> {
    return new Promise((resolve) => {
      const category_id = category === "background" ? 1 : 2;
      this.get(`userskinfile/${category_id}/${owner}`)
        .then(async (result) => {
          if (!result.link) {
            resolve(null);
            return;
          }
          const nftData = await this.getNftContentByDataUrl(result.link);
          if (!nftData) {
            resolve(null);
            return;
          }
          resolve(nftData.image)
        })
        .catch((e) => {
          console.log(e);
          resolve(null);
        });
    });     
  }

  public async selectUserSkin (nftAddress: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.post("userskin/register", { item: nftAddress}, "POST")
      .then((res) => {
         resolve(!!res.success);
       })
       .catch((e) => {
         console.log(e);
         resolve(false);
      });
    })
  }
}

export const backend = BackendService.getInstance();
