import * as React from "react";
import PictureAvatar from "@/components/molecules/mint/picture-avatar";
import MintButton from "@/components/molecules/mint/mint-button";
import ContractCard from "@/components/atomics/contract-card";
import Direction from "@/components/atomics/direction";
import { nftData } from "@/data/nft-data";
import NFTCard from "@/components/atomics/nft-card";
import OwnerView from "@/components/atomics/owner-view";
import { useNavigate } from "react-router-dom";
import { CollectionDataCache, CollectionFileData, ItemFileData } from "@/types";
import { useCollectionContract } from "@/hooks/useCollectionContract";
import { useMemo } from "react";
import clsx from "clsx";
import { useState, useEffect } from "react";
import MotionModal from "@/components/atomics/motion-modal";
import NftSuccessMessage from "@/components/atomics/nft-success-message";
import NftErrorMessage from "@/components/atomics/nft-error-message";
import { backend } from "@/services/backend";
import { useSnackbar } from "notistack";
import { useWallet } from "@/context/WalletContext";
import ShareUrl from "@/components/atomics/share-url";
import LoadMoreButton from "@/components/atomics/load-more-button";
import { generateBlackSquare, imageVideoPreloader } from "@/utils/storage";

interface MintProps {
    collection: string | undefined,
    collectionInfo: CollectionDataCache;
    // collectionMasterInfo: CollectionMasterStandardData;
}

interface ItemInCollection {
    address: string;
    index: number;
}

const INITIAL_DISPLAY_COUNT = 8;
const LOAD_MORE_INCREMENT = 4;
const LOADING_DELAY = 300;
const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export default function Mint({ collection, collectionInfo }: MintProps) {
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [metadata, setMetadata] = useState<any | null>(null);
    const [tonPrice, setTonPrice] = useState<any | null>(null);
    const [itemMetadata, setItemMetadata] = useState<ItemFileData | null>(null);
    const [isMinting, setIsMinting] = useState(false);
    const { walletAddress } = useWallet();
    const { mint } = useCollectionContract();
    const [items, setItems] = useState<ItemInCollection[]>([]);
    const [royaltyPercent, setRoyaltyPercent] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoadingItems, setIsLoadingItems] = useState(false);
    const [imageMimeType, setImageMimeType] = React.useState<string>();
    const [previewImage, setPreviewImage] = React.useState<string>(generateBlackSquare(128, 128, false) as string);

    useEffect(() => {
        if (collectionInfo?.data_url && collectionInfo?.item_data_url) {
            fetchMetadata(collectionInfo.data_url, collectionInfo.item_data_url);
        }
    }, [collectionInfo?.data_url, collectionInfo?.item_data_url]);

    useEffect(() => {
        const fetchConversionRates = async () => {
            try {
                const response = await fetch(
                    "https://api.coingecko.com/api/v3/simple/price?ids=the-open-network&vs_currencies=usd"
                );
                const data = await response.json();
                if (data['the-open-network']) {
                    setTonPrice(data['the-open-network'].usd);
                }
            } catch (error) {
                console.error("Error fetching conversion rates:", error);
            }
        };
        fetchConversionRates();
    }, []);

    useEffect(() => {
        if (collection) {
            loadItems();
            loadRoyaltyParams();
        }
    }, [collection]);

    const loadItems = async () => {
        if (!collection) return;

        setIsLoadingItems(true);
        try {
            const newItems = await backend.getItemsInCollection({
                collectionAddress: collection,
                page: currentPage,
                perPage: INITIAL_DISPLAY_COUNT
            });

            setItems(newItems);
            setCurrentPage(prev => prev + 1);
        } catch (error) {
            console.error('Error loading items:', error);
        } finally {
            setIsLoadingItems(false);
        }
    };

    const loadRoyaltyParams = async () => {
        if (!collection) return;
        const royaltyParams = await backend.getCollectionRoyaltyParams(collection);
        setRoyaltyPercent((royaltyParams?.numerator ?? 0) / (royaltyParams?.denominator ?? 1) * 100);
    }

    const [state, setState] = React.useState({
        displayCount: INITIAL_DISPLAY_COUNT,
        isLoading: false,
        isOwnerView: false
    });


    const stats = useMemo(() => ({
        name: metadata?.name || '',
        description: metadata?.description || '',
        background: metadata?.cover_image || '',
        image: metadata?.image || '',
        nft_image: itemMetadata?.image || '',
        minted_nfts: collectionInfo?.next_item_index || 0,
        contract_address: collectionInfo?.address || '',
        owner_address: collectionInfo?.owner || '',
        nextItemAddress: collectionInfo?.next_item_address || '',
        total: collectionInfo?.mint_limit || 0,
        price: (collectionInfo?.price || 0) / 1000000000,
        earned: (collectionInfo?.next_item_index || 0) * ((collectionInfo?.price || 0) / 1000000000),
        available: collectionInfo?.mint_limit > 0
            ? (collectionInfo?.mint_limit || 0) - (collectionInfo?.next_item_index || 0)
            : 0
    }), [metadata, itemMetadata, collectionInfo]);

    useEffect(() => {
        imageVideoPreloader(stats.nft_image)
        .then((data) => {
          setImageMimeType(data.mimeType);
          if (data.mimeType.startsWith("video/")) {
            setPreviewImage(data.frame || stats.nft_image);
          } else {
            setPreviewImage(stats.nft_image);        
          }
        })
        .catch((e) => console.log(e));
    }, [stats.nft_image])


    const fetchMetadata = async (collectionUrl: string, itemUrl: string) => {
        try {
            const collectionResponse = await fetch(collectionUrl);
            const collectionData = await collectionResponse.json();
            setMetadata(collectionData);

            const itemResponse = await fetch(itemUrl);
            const itemData = await itemResponse.json();
            setItemMetadata(itemData);
        } catch (error) {
            console.error('Error fetching metadata:', error);
        }
    };

    const mintNft = async () => {
        if (!collection) {
            console.error('Collection is undefined');
            return;
        }
        if (!walletAddress) {
            snackbar.enqueueSnackbar("Please connect your wallet first", { variant: "error", autoHideDuration: 1000 });
            return;
        }
        try {
            setIsMinting(true);
            const result = await mint(collection);
            console.log(result);
            if (result) {
                const MAX_ATTEMPTS = 24;
                let attempts = 0;
                if (collectionInfo?.next_item_address) {
                    while (attempts < MAX_ATTEMPTS) {
                        const isDeployed = await backend.getDeployStatus(collectionInfo?.next_item_address);
                        if (isDeployed) {
                            setIsMinting(false);
                            setShowSuccessMessage(true);
                            return;
                        }
                        await sleep(5000);
                        attempts++;
                    }
                }

                setIsMinting(false);
                setShowErrorMessage(true);
                setErrorMessage("Deployment timeout after 2 minutes!");
            } else {
                setShowErrorMessage(true);
                setIsMinting(false);
            }
        } catch (error) {
            if (error.message.includes('Reject request')) {
                console.warn('Transaction was cancelled by the user.');
                snackbar.enqueueSnackbar('Transaction was cancelled by the user.', { variant: "error", autoHideDuration: 1000 });
                setErrorMessage('Transaction was cancelled.');
            } else {
                console.error('Minting failed:', error);
                setErrorMessage('An unexpected error occurred.');
            }
            setShowErrorMessage(true);
        } finally {
            setIsMinting(false);
        }
    }

    const handleLoadMore = React.useCallback(() => {
        loadItems();
    }, [collection, currentPage]);

    const handleViewToggle = React.useCallback(() => {
        setState(prev => ({ ...prev, isOwnerView: !prev.isOwnerView }));
    }, []);

    const displayedNFTs = useMemo(() => {
        return items.map((item, index) => ({
            id: `#${item.index}`,
            image: itemMetadata?.image || '',
            name: `${metadata?.name || ''} #${item.index}`,
            mintedAddress: item.address
        }));
    }, [items, itemMetadata, metadata]);

    return (
        <div className="w-full">
            {walletAddress === stats.owner_address && (
                <OwnerView
                    isOwnerView={state.isOwnerView}
                    handleViewToggle={handleViewToggle}
                />
            )}

            <div className="relative w-full h-[180px]">
                <img src={stats.background} alt="background" />
                <div className="w-full absolute left-[50%] translate-x-[-50%] translate-y-[-80px] flex flex-col items-center justify-center">
                    <PictureAvatar className="w-[160px]" image={stats.image} />
                    <div className="text-[32px]">
                        <span>{stats.name}</span>
                    </div>
                    <div className="text-[24px] text-[#6D6D6D]">
                        <span>ROYALTY: {royaltyPercent}%</span>
                    </div>
                </div>
                <ShareUrl
                    className={`absolute bottom-[-49px] right-[28px] z-[1] hover:cursor-pointer`}
                    buttonColor="#0099FE"
                    path={`collection/${collection}`}
                    walletAddress={walletAddress}
                />
            </div>
            <div className="relative">
                <MotionModal isOpen={showSuccessMessage}>
                    <NftSuccessMessage
                        className="w-full bg-black"
                        avatar={stats.image}
                        index={stats.minted_nfts}
                        collectionName={stats.name}
                        onClose={() => setShowSuccessMessage(false)}
                        onClick={() => { navigate(`/nft/${stats.nextItemAddress}`) }}
                    />
                </MotionModal>
                <MotionModal isOpen={showErrorMessage}>
                    <NftErrorMessage
                        className="w-full bg-black"
                        avatar={stats.image}
                        index={stats.minted_nfts}
                        collectionName={stats.name}
                        errorMessage="Error message"
                        onClose={() => setShowErrorMessage(false)}
                        onClick={() => setShowErrorMessage(false)}
                    />
                </MotionModal>
            </div>

            <div className="px-[30px]">
                {!state.isOwnerView ? (
                    <div className="w-full h-[310px] pt-[183px] flex justify-between opacity-100 transition-all duration-500 ease-in-out">
                        <div className="w-[36%] aspect-square relative">
                            <img src={previewImage} alt="mint" />
                            <div className="absolute z-[2] top-[0px] w-full h-full">
                                <img src="/assets/avatar-bg.png" alt="mint" />
                            </div>
                        </div>
                        <MintButton
                            className="w-[60%]"
                            tonPrice={tonPrice}
                            price={stats.price}
                            onClick={mintNft}
                            isLoading={isMinting}
                        />
                    </div>
                ) : (
                    <div className="h-[310px] flex flex-col items-center justify-center pt-[183px] opacity-100 transition-all duration-500 ease-in-out">
                        <span className="text-[30px] text-[#0099FE]">EARNED</span>
                        <span className="text-[40px]">{stats.earned}</span>
                        <span className="text-[30px] text-[#0099FE]">TON</span>
                    </div>
                )}

                <div className="flex justify-center gap-[10px] pt-[30px]">
                    <div className="flex flex-col items-center justify-center">
                        <span className="text-[24px] text-[#0099FE]">TOTAL</span>
                        {stats.total === 0 ? (
                            <div className="flex items-center justify-center pt-[14px] pb-[10px]">
                                <img src="/assets/infinity.svg" alt="infinity" className="h-[24px]" />
                            </div>
                        ) : (
                            <span className="text-[32px]">{stats.total}</span>
                        )}
                        <span className="text-[20px] text-[#0099FE]">NFT</span>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <span className="text-[24px] text-[#0099FE]">MINTED</span>
                        <span className="text-[32px]">{stats.minted_nfts}</span>
                        <span className="text-[20px] text-[#0099FE]">NFT</span>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <span className="text-[24px] text-[#0099FE]">AVAILABLE</span>
                        {stats.available === 0 ? (
                            <div className="flex items-center justify-center pt-[14px] pb-[10px]">
                                <img src="/assets/infinity.svg" alt="infinity" className="h-[24px]" />
                            </div>
                        ) : (
                            <span className="text-[32px]">{stats.available}</span>
                        )}
                        <span className="text-[20px] text-[#0099FE]">NFT</span>
                    </div>
                </div>

                <div className="pt-[30px]">
                    <ContractCard
                        className="w-full pt-[10px] pb-[10px]"
                        length={8}
                        text="CONTRACT"
                        address={stats.contract_address}
                        onClick={() => { }}
                    />
                    <ContractCard
                        className="w-full py-[10px]"
                        length={8}
                        text="OWNER"
                        address={stats.owner_address}
                        onClick={() => { navigate(`/owner/${stats.owner_address}`) }}
                    />
                </div>

                <div className="flex flex-col">
                    <span className="text-[24px]">DESCRIPTION:</span>
                    <p className="font-spline-sans-mono text-[13px]  text-[#757575]">
                        {stats.description}
                    </p>
                </div>
                <div className="pt-[20px] pb-[23px] flex pr-[1px] items-end justify-end">
                    <div className="right-[10px] flex items-center justify-center gap-[10px]">
                        <span className="text-[#0099FE] text-[24px]">MORE</span>
                        <Direction color="#0099FE" className="w-[11px] h-[19px] rotate-[90deg]" />
                    </div>
                </div>
            </div>

            <div className="w-full bg-white pt-[20px] pb-[20px] px-[30px]">
                <div className="pb-[23px]">
                    <span className="text-[30px] text-black">ALREADY CREATED</span>
                </div>
                <div className="w-full grid grid-cols-4 gap-[10px] transition-all duration-500 ease-in-out">
                    {displayedNFTs.map((item, index) => (
                        <NFTCard
                            key={`nft-${item.id}-${index}`}
                            className={clsx(
                                'w-full flex-shrink-0 transition-all duration-300 ease-in-out',
                                isLoadingItems && 'animate-pulse opacity-60',
                                !isLoadingItems && 'opacity-100'
                            )}
                            onClick={() => { navigate(`/nft/${item.mintedAddress}`) }}
                            {...item}
                            image={previewImage}
                        />
                    ))}
                </div>
                {stats.minted_nfts > items.length && (
                    <LoadMoreButton
                        isLoading={isLoadingItems}
                        hasMore={items.length < stats.minted_nfts}
                        onLoadMore={handleLoadMore}
                    />
                )}
            </div>

        </div>
    )
}
