import React, { useEffect } from 'react';
import { useState } from 'react';
import { generateMiniAppCustomUrl } from "@/utils/telegram";
import ShareButton from "@/components/atomics/share-button";
import { backend } from '@/services/backend';
import { useSnackbar } from 'notistack';
interface ShareUrlProps {
  className?: string;
  walletAddress?: string | null;
  path: string;
  buttonColor?: string;
  disabled?: boolean;
}

export default function ShareUrl({ 
  className = '', 
  walletAddress, 
  path,
  buttonColor = '#0099FE',
  disabled = false
}: ShareUrlProps) {
  const [showCopied, setShowCopied] = useState(false);
  const [referralLink, setReferralLink] = useState<string>(walletAddress || "");
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    if (walletAddress)
    backend.createCustomReferralLink(walletAddress).then((res) => {
      if (res) {
        setReferralLink(res)
      } else {
        setReferralLink(walletAddress)
      }
    })
  }, [walletAddress])

  const handleShare = () => {
    if (!walletAddress) {
      enqueueSnackbar("Connect wallet to make link referral", { 
        variant: "error", 
        autoHideDuration: 2000 
    });
    }
    const param = generateMiniAppCustomUrl(path.split("?")[0], referralLink || undefined);
    console.log("Generated param:", param);
    if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.openTelegramLink(
            `https://t.me/share/url?url=${encodeURIComponent(param)}`
        );
    } else {
      const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(param)}`;
      window.open(telegramShareUrl, '_blank');
    }
  };

  return (
    <div className={className}>
        <div className="relative">
            {showCopied && (
                <div className="absolute top-[-52px] right-[-20px] z-[12] text-white bg-black px-2 py-1 rounded text-sm">
                Copied!
                </div>
            )}
     
            <ShareButton
                color={buttonColor}
                onClick={handleShare}
            />
        </div>
    </div>
  );
}