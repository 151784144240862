import React, { useEffect, useState } from 'react';
import CancelButton from './cancel-button';
import Button from './button';
import { fetchData } from '@/types/collection';

interface ConfirmModalProps {
    onClose: () => void;
    onApply?: () => void;
    item: any;
    title: string;
}

export const ConfirmModal = ({ onClose, onApply, item, title }: ConfirmModalProps) => {
    const [metadataData, setMetadataData] = useState<any>(null);

    useEffect(() => {
        const fetchNFTData = async () => {
            try {
                const [metadataData] = await Promise.all([
                    fetchData(item.data_url),
                ]);
                setMetadataData(metadataData);
            } catch (error) {
                console.error('Error fetching NFT data:', error);
            }
        };

        fetchNFTData();
    }, [item]);

    return (
        <div className="flex flex-col w-full h-full text-[33px] text-white">
            <div className="flex justify-between items-center p-[20px] bg-[#0099FE] w-full relative">
                <span>CONFIRM</span>
                <CancelButton
                    onClick={onClose}
                />
            </div>

            <span className="text-[26px] p-[20px] text-white">{metadataData?.name}</span>
            <img src={title === 'AVATAR' ? metadataData?.image : metadataData?.cover_image} alt="nft-image" className="max-w-[430px] max-h-[430px] object-cover" />
            <div className="flex flex-col w-full text-[15px] p-[20px] text-white">
                <p>{metadataData?.description}</p>
            </div>

            <div className="flex justify-center items-center pb-[50px]">
                <Button
                    className="w-full custom-aspect-320-63 text-[24px] hover:cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out"
                    image="/assets/button.svg"
                    text="APPLY"
                    onClick={onApply}
                />
            </div>
        </div>
    );
};